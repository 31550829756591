/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { v4 } from "uuid";
import { Spacer, Text, theme } from "@nordcloud/gnui";
import { EventActionResourceReportResult } from "~/generated/graphql";
import { isNotEmpty } from "~/tools";

type Props = { result: EventActionResourceReportResult };

export function ResourceDetails({ result }: Props) {
  const {
    __typename: IgnoredTypename,
    outputUri: IgnoredOutputUri,
    output: IgnoredOutput,
    ...rest
  } = result;
  const prepareResult = Object.keys(rest).map((i, index) => {
    return {
      id: v4(),
      label: i,
      data: Object.values(rest)[index] ?? [],
    };
  });

  return (
    <>
      {prepareResult.map((item) => {
        return (
          <div key={item.id}>
            <When condition={isNotEmpty(item.data)}>
              <Text weight="bold" mb={theme.spacing.spacing02}>
                {`${item.label.replace(/^./, (firstChar) =>
                  firstChar.toUpperCase()
                )}:`}
              </Text>
              {item.data?.map((i) => (
                <Text mb={theme.spacing.spacing01} key={i.id}>
                  {i.name} {`(${i.id})`}
                </Text>
              ))}
              <Spacer height={theme.spacing.spacing01} />
            </When>
          </div>
        );
      })}
    </>
  );
}
