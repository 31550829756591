/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Box, Text, theme } from "@nordcloud/gnui";
import { PlanActionNotificationTriggerType } from "~/generated/graphql";
import { NoData } from "~/components";
import { isEmpty } from "~/tools";
import { getLabelText } from "~/views/plans/PlanCreate/components/NotificationItem/constants";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";
import { convertMinutesToReadableTime } from "~/views/plans/utils";

export function ActionNotification() {
  const { planAction } = usePlan();
  const notifications = planAction?.notificationGroups;

  if (isEmpty(notifications ?? [])) {
    return (
      <NoData
        hasIcon
        message="There are no Resource and Action-based Notifications"
      />
    );
  }

  return (
    <>
      <Text weight="medium">Resource and Action-based Notifications</Text>
      {notifications?.map((item, index) => {
        if (item.triggerEvent) {
          return (
            <Box
              boxStyle="lightGrey"
              key={item.id}
              mb={theme.spacing.spacing03}
            >
              <Text size="sm" tag="div" color={theme.color.text.text02}>
                Notification #{index + 1}
              </Text>
              <Text data-testid="text-action-notification" tag="div">
                <>
                  Send to{" "}
                  <Text tag="span" color={theme.color.text.info}>
                    {item.notificationGroup?.name}{" "}
                  </Text>
                  when{" "}
                  <Text tag="span" weight="medium">
                    {`${item.scope?.toLowerCase()} ${getLabelText(
                      item.triggerEvent
                    ).toLowerCase()}`}
                  </Text>
                  <When
                    condition={[
                      PlanActionNotificationTriggerType.EventActionsIncoming,
                      PlanActionNotificationTriggerType.EventActionsNotApproved,
                    ].includes(item.triggerEvent)}
                  >
                    {" "}
                    in{" "}
                    <Text tag="span" weight="medium">
                      {convertMinutesToReadableTime(Number(item?.inAdvance))}
                    </Text>
                  </When>
                </>
              </Text>
            </Box>
          );
        }
      })}
    </>
  );
}
