/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { FlexContainer, Spacer, Tab, Tabs, Text, theme } from "@nordcloud/gnui";
import { PlanAction } from "~/generated/graphql";
import { DataDisplayWrapper } from "~/components";
import { ROUTES } from "~/routing/routes";
import { isNotEmpty } from "~/tools";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { convertMinutesToTime } from "~/views/events/helpers";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";

type Props = {
  planAction?: PlanAction;
  planId?: string;
};

export function IncomingEventActionSettingsSection({
  planAction,
  planId,
}: Props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={<TabIcon caption="Action Settings" icon="settings" />}
          width="auto"
        >
          <Text weight="bold">General Action Settings</Text>
          <Text size="sm" tag="div">
            Action Name
          </Text>
          <FlexContainer direction="row" justifyContent="space-between">
            <DataDisplayWrapper hasData={planAction?.action.name != null}>
              <Text>{planAction?.action.name}</Text>
            </DataDisplayWrapper>
          </FlexContainer>
          <DisplayExecutionPolicy policy={planAction?.executionPolicy} />
          <DisplayWindowDuration windowDuration={planAction?.windowDuration} />
          <DisplayBooleanProperties
            title="Resource Operation"
            condition={planAction?.runInSequence}
            options={["Sequenced", "Simultaneous"]}
          />
          <When condition={planAction?.skipWindow}>
            <Text size="sm" tag="div">
              Ignore Window
            </Text>
            <Text mb={theme.spacing.spacing01}>
              {convertSkipWindow(planAction?.skipWindow)}
            </Text>
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <When condition={isNotEmpty(planAction?.inputParameters ?? [])}>
            <Text size="sm" tag="div">
              Action Delay
            </Text>
            <DataDisplayWrapper
              hasData={isNotEmpty(planAction?.inputParameters ?? [])}
            >
              {planAction?.inputParameters?.map(({ key, value }) => (
                <Text mb={theme.spacing.spacing01} key={key}>
                  {convertMinutesToTime(Number(value))}
                </Text>
              ))}
            </DataDisplayWrapper>
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <Link to={`${ROUTES.plans.index}/${planId}`}>
            Go to plan to edit settings
          </Link>
        </Tab>
      </Tabs>
    </>
  );
}
