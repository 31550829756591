/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { CronExpression, parseExpression } from "cron-parser";
import dayjs from "dayjs";
import { v4 } from "uuid";
import { ExtendedTooltip, Text, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components/Styled";
import { dateFormat } from "~/constants";
import { TextDescriptionCell } from "../cells/TextDescriptionCell";

type Props = {
  cronExpression?: string;
  timezone?: string;
};

export function NextDateCronCell({ cronExpression, timezone }: Props) {
  const parseOptions = timezone ? { tz: timezone } : undefined;
  const interval =
    cronExpression && parseExpression(cronExpression, parseOptions);

  return (
    <TextDescriptionCell
      text={
        interval
          ? dayjs(interval.next().toString()).format(
              dateFormat.dayMonthYearHour
            )
          : "Time not defined"
      }
      description={cronExpression ?? "Run plan manually"}
      tooltip={
        cronExpression && (
          <Tooltip
            tooltip={
              interval &&
              Array(3)
                .fill(0)
                .map(() => (
                  <Text key={v4()} size="sm" mb={0} color="text01">
                    Next: {getNextDate(interval, dateFormat.dayMonthYearHour)}
                  </Text>
                ))
            }
            description={`Cron: ${cronExpression}`}
          />
        )
      }
    />
  );
}

type TooltipProps = {
  tooltip: ReactNode;
  description: string;
};

function Tooltip({ tooltip, description }: TooltipProps) {
  return (
    <ExtendedTooltip caption={<>{tooltip}</>} hideTimeout={0}>
      <TooltipText
        size="sm"
        tag="span"
        mr={theme.spacing.spacing01}
        weight="regular"
        color={theme.color.text.text02}
        css={{
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          textDecorationThickness: "1px",
          textDecorationColor: theme.color.text.text02,
          textUnderlineOffset: theme.spacing.spacing01,
        }}
      >
        {description}
      </TooltipText>
    </ExtendedTooltip>
  );
}

function getNextDate(interval: CronExpression, format: string) {
  return dayjs(interval.next().toString()).format(format);
}
