/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExtendedTooltip, FlexContainer, SVGIcon } from "@nordcloud/gnui";
import { EventAction, EventStatus } from "~/generated/graphql";
import { isEmpty } from "~/tools";
import { Status, getActionStatus, getEventStatus } from "../helpers";

type Props = {
  status?: string;
  actionLength?: number;
  type: "Event" | "FutureEvent" | "Action";
  size?: "md" | "lg";
  actions?: Array<EventAction>;
};

export function TooltipIconStatus(props: Props) {
  const statusInfo = getStatusInfo(props);

  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <ExtendedTooltip
        status={statusInfo.tooltipColor}
        caption={statusInfo.description}
        hideTimeout={0}
        position="start"
      >
        <FlexContainer justifyContent="center" alignItems="center">
          <SVGIcon
            size={props.size}
            name={statusInfo.icon}
            color={statusInfo.color()}
          />
        </FlexContainer>
      </ExtendedTooltip>
    </FlexContainer>
  );
}

function getStatusInfo({
  status,
  actionLength,
  type,
  actions = [],
}: Omit<Props, "size">): Status {
  if (type === ACTION_TYPE) {
    return getActionStatus(status);
  }

  const eventStatus = getEventStatus(status, actionLength);

  if (type === FUTURE_EVENT_TYPE) {
    return eventStatus;
  }

  if (status === EventStatus.Success) {
    const icon = "statusSuccessDetailed";

    if (isEmpty(actions)) {
      return {
        ...eventStatus,
        icon: icon,
        description: "No actions added.",
      };
    }

    if (actions?.every((action) => isEmpty(action.resourceGroups ?? []))) {
      return {
        ...eventStatus,
        icon: icon,
        description: "No resource group added.",
      };
    }
  }

  return eventStatus;
}

const ACTION_TYPE = "Action";
const FUTURE_EVENT_TYPE = "FutureEvent";
