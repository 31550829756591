/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type ColorLabelProps = {
  color: string;
};

export const ColorLabel = styled.div<ColorLabelProps>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${theme.spacing.spacing02};
  background-color: ${(props) => props.color};
  border-top-left-radius: ${theme.radius.md};
  border-bottom-left-radius: ${theme.radius.md};
`;

type ToggleButtonItemProps = {
  color: string;
  active: boolean;
};

export const ButtonText = styled.div`
  display: flex;
  flex: 1;
  background: none;
  padding: ${theme.spacing.spacing03} ${theme.spacing.spacing02};
  border: none;
  cursor: pointer;
`;

export const ToggleButtonItem = styled.div<ToggleButtonItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: ${theme.iconSize.md};
  padding: 0 ${theme.spacing.spacing03} 0 ${theme.spacing.spacing04};
  border: ${theme.borderDefault};
  border-color: ${theme.color.background.ui04};
  border-radius: ${theme.radius.md};
  background-color: ${({ active }) =>
    active ? theme.color.background.ui04 : theme.color.background.ui03};
  transition: ${theme.transition};

  &:hover {
    background-color: ${theme.color.background.ui04};
    border-color: ${theme.colors.black};
  }
`;

export const IconWrapper = styled.div`
  padding: ${theme.spacing.spacing02};
  width: ${theme.iconSize.md};
`;

export const Wrap = styled.div`
  width: 100%;
  margin: ${theme.spacing.spacing01} 0;
`;
