/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import {
  EventActionResourceState,
  Group,
  Maybe,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isNotEmpty, isNotNil } from "~/tools";
import { DateRange } from "./types";

export const defaultRangeLabel = "Date Range";

export function getRangeLabel(
  range?: DateRange,
  startDate?: string,
  endDate?: string
) {
  if (isNotNil(startDate) && isNotNil(endDate)) {
    return `${dayjs(startDate).format(dateFormat.shortDate)} - ${dayjs(
      endDate
    ).format(dateFormat.shortDate)}`;
  }

  if (range?.startDate) {
    const rangeTo =
      range.endDate === undefined
        ? ""
        : dayjs(range.endDate).format(dateFormat.datePickerShortDate);

    return `${dayjs(range.startDate).format(
      dateFormat.datePickerShortDate
    )} - ${rangeTo}`;
  }

  return defaultRangeLabel;
}

export function getResourceStatusesCount(
  resourceStates: EventActionResourceState[],
  statusesToCheck: ResourceExecutionStatus[]
): number | undefined {
  return isNotEmpty(resourceStates)
    ? sum(
        statusesToCheck.map(
          (resourceStatus) =>
            resourceStates.filter((state) => state.status === resourceStatus)
              .length
        )
      )
    : undefined;
}

export function getTotalResourceCount(
  resourceGroups: Maybe<Group[]> | undefined
): number | undefined {
  return isNotEmpty(resourceGroups ?? [])
    ? sum(
        resourceGroups?.map((rg) => {
          return rg.totalNumberOfResources;
        })
      )
    : undefined;
}

function sum(arr: number[] | undefined): number | undefined {
  return arr?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
}
