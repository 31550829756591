/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { Case, Switch } from "react-if";
import { theme, Box } from "@nordcloud/gnui";
import { Schedule } from "~/views/plans/types";
import { scheduleSchema } from "~/views/plans/utils";
import { PlanField } from "../constants";
import { FormData } from "../formConfig";
import { usePlanWizard } from "../PlanProvider";
import { FormField, TimeUnits } from "../types";
import { ScheduleCron } from "./ScheduleCron";
import { SelectSchedule } from "./SelectSchedule";
import { isFormValid } from "./utils";

type Props = {
  nextStep: () => void;
};

export function PlanScheduleForm({ nextStep }: Props) {
  const [scheduleType, setScheduleType] = useState(Schedule.repeated);
  const { planData, setPlanData } = usePlanWizard();

  const formMethods = useForm({
    resolver: zodResolver(scheduleSchema),
    defaultValues: {
      [FormField.SCHEDULE_TIMEZONE]:
        planData?.schedule_plan?.scheduleTimezone ?? "",
      [FormField.SCHEDULE_CRON]: planData?.schedule_plan?.scheduleCron ?? "",
      [FormField.SCHEDULE_OFFSET]: planData?.schedule_plan?.scheduleOffset ?? 0,
      [FormField.SCHEDULE_OFFSET_UNIT]: TimeUnits.minutes,
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const submit: SubmitHandler<FormData> = (formData) => {
    if (!isFormValid(errors)) {
      setError(FormField.SCHEDULE_CRON, {
        message: errors.scheduleCron?.message,
      });

      return;
    }

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: formData,
    }));

    nextStep();
  };

  const handleScheduleChange = (scope: Schedule) => {
    setScheduleType(scope);
  };

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        Schedule Options
        <Box
          boxStyle="lightGrey"
          spacing="spacing04"
          mt={theme.spacing.spacing04}
        >
          <FormProvider {...formMethods}>
            <form id="planScheduleForm" onSubmit={handleSubmit(submit)}>
              <SelectSchedule onScopeChange={handleScheduleChange} />
              <Switch>
                <Case condition={scheduleType === Schedule.repeated}>
                  <ScheduleCron
                    planDataForm={planData}
                    setPlanData={setPlanData}
                  />
                </Case>
                <Case condition={scheduleType === Schedule.runOnce}>
                  <div />
                </Case>
                <Case condition={scheduleType === Schedule.runManually}>
                  <div />
                </Case>
              </Switch>
            </form>
          </FormProvider>
        </Box>
      </Col>
    </Row>
  );
}
