/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import { FlexContainer, Spacer, Tab, Tabs, Text, theme } from "@nordcloud/gnui";
import { EventAction, ExecutionStatus } from "~/generated/graphql";
import { DataDisplayWrapper } from "~/components";
import { ROUTES } from "~/routing/routes";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { ApproveEvent } from "~/views/events/components/ApproveEvent/ApproveEvent";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { RefetchOptions, convertMinutesToTime } from "~/views/events/helpers";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";

type Props = {
  eventAction?: EventAction;
  planId?: string;
};

export function ActionSettingsSection({ eventAction, planId }: Props) {
  const [tab, setTab] = useState(0);

  const { event } = useEvent();
  const actions = event?.actions?.filter(
    (action: EventAction) =>
      action.globalState?.status === ExecutionStatus.ApprovalRequired
  );

  const isApproveButtonVisible = event?.actions
    ?.filter((eventA: EventAction) => eventA.id === eventAction?.id)
    ?.map((action: EventAction) => action.globalState?.status)
    .some(
      (state: ExecutionStatus) => state === ExecutionStatus.ApprovalRequired
    );

  const isDelay = eventAction?.action?.name === SystemActionNames.DELAY;

  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={<TabIcon caption="Action Settings" icon="settings" />}
          width="auto"
        >
          <Text weight="bold">General Action Settings</Text>
          <Text size="sm" tag="div">
            Action Name
          </Text>
          <FlexContainer
            direction="row"
            justifyContent="space-between"
            mb={theme.spacing.spacing02}
          >
            <DataDisplayWrapper hasData={eventAction?.action?.name != null}>
              <Text>{eventAction?.name}</Text>
            </DataDisplayWrapper>
          </FlexContainer>
          <DisplayExecutionPolicy policy={eventAction?.executionPolicy} />
          <If condition={isDelay}>
            <Then>
              <Text size="sm" tag="div">
                Action Delay
              </Text>
              <Text mb={theme.spacing.spacing01}>
                {convertMinutesToTime(Number(eventAction?.windowDuration))}
              </Text>
              <Spacer height={theme.spacing.spacing03} />
            </Then>
            <Else>
              <DisplayWindowDuration
                windowDuration={eventAction?.windowDuration}
              />
            </Else>
          </If>
          <DisplayBooleanProperties
            title="Resource Operation"
            condition={eventAction?.runInSequence}
            options={["Sequenced", "Simultaneous"]}
          />
          <When condition={eventAction?.skipWindow}>
            <Text size="sm" tag="div">
              Ignore Window
            </Text>
            <Text mb={theme.spacing.spacing01}>
              {convertSkipWindow(eventAction?.skipWindow)}
            </Text>
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <When condition={isApproveButtonVisible}>
            <ApproveEvent
              actions={actions?.slice(0, 1)}
              eventId={event?.id}
              refetchQueries={RefetchOptions.EVENT}
            />
          </When>
          <Spacer height={theme.spacing.spacing02} />
          <Link to={`${ROUTES.plans.index}/${planId}`}>
            Go to plan to edit settings
          </Link>
        </Tab>
      </Tabs>
    </>
  );
}
