/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Box } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { NoData } from "~/components/NoData";
import { useQueryState } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { isEmpty, isNotNil } from "~/tools";
import { UserCreate } from "./components/UserCreate";
import { UsersTable } from "./components/UsersTable";
import { useGetRoleBindingsByUsers } from "./hooks";
import { QuerySort } from "./types/QuerySort";

export function UsersPage() {
  const breadcrumbsLabels = [
    { label: "Dashboard", uri: "/" },
    { label: "Users", uri: ROUTES.permissions.index },
  ];
  const { queryState } = useQueryState<QuerySort>();

  const {
    data: fetchedData,
    loading,
    count,
  } = useGetRoleBindingsByUsers({
    page: queryState.page,
    limit: "100",
  });

  const data = fetchedData.filter(isNotNil);

  const userEmails = data.map((item) => item.subject);
  return (
    <>
      <Row>
        <Col>
          <BreadcrumbsBox title="Users" customBreadcrumbs={breadcrumbsLabels}>
            <UserCreate users={userEmails} />
          </BreadcrumbsBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <If condition={!loading && isEmpty(data)}>
              <Then>
                <NoData hasIcon message="There are no users." />
              </Then>
              <Else>
                <UsersTable {...{ loading, data, count }} />
              </Else>
            </If>
          </Box>
        </Col>
      </Row>
    </>
  );
}
