/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button, ExtendedTooltip } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { ActionLogModal } from "../Tabs/Details/components/ActionLogModal";

type Props = {
  outputUri?: string | null;
  actionLogLabel: string;
};

export function LogOutput({ outputUri, actionLogLabel }: Props) {
  const { isOpen, open, close } = useDisclosure();

  return (
    <>
      <ExtendedTooltip caption="Log Output" position="start">
        <Button severity="low" icon="eventLog" onClick={open} />
      </ExtendedTooltip>
      <When condition={isOpen}>
        <ActionLogModal
          close={close}
          isOpen={isOpen}
          actionOutputUrl={outputUri}
          actionLogLabel={actionLogLabel}
        />
      </When>
    </>
  );
}
