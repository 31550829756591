/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 } from "uuid";
import { ExtendedTooltip, Text, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components";

type Props = {
  dates: string[];
};

export function CronNextDates({ dates }: Props) {
  const firstDate = dates[0];

  if (firstDate) {
    const firstInterval = `Next: ${firstDate}`;

    return (
      <Text data-testid="next-date-cron" tag="div" size="sm">
        <Tooltip tooltip={dates} description={firstInterval} />
      </Text>
    );
  }

  return null;
}

type TooltipProps = {
  tooltip: string[];
  description: string;
};

function Tooltip({ tooltip, description }: TooltipProps) {
  const displayTooltip = tooltip?.map((item: string) => {
    return (
      <Text key={v4()} size="sm" mb={0} color="text01">
        Next: {item}
      </Text>
    );
  });

  return (
    <ExtendedTooltip
      caption={<>{displayTooltip}</>}
      hideTimeout={0}
      zIndex={theme.zindex.topoftheworld}
    >
      <TooltipText
        size="sm"
        tag="span"
        mr={theme.spacing.spacing01}
        weight="regular"
        color={theme.color.text.text02}
        css={{
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          textDecorationThickness: "1px",
          textDecorationColor: theme.color.text.text02,
          textUnderlineOffset: theme.spacing.spacing01,
        }}
      >
        {description}
      </TooltipText>
    </ExtendedTooltip>
  );
}
