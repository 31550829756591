/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { When } from "react-if";
import { useHistory } from "react-router-dom";
import { PopoverControls } from "~/components";
import { ROUTES } from "~/routing/routes";
import { isEmpty } from "~/tools";
import { DeletePlan } from "./DeletePlan";
import { RunPlan } from "./RunPlan";

type Props = {
  planId: string;
  planName: string;
  isPlanActive?: boolean;
  onSuccess?: () => void;
  size?: "large" | "small";
};

export function Actions({
  planId,
  planName,
  size = "small",
  isPlanActive = false,
}: Props) {
  const noPlanId = isEmpty(planId ?? []);

  type State = {
    isDeleteModalOpen: boolean;
    isRunPlanModalOpen: boolean;
  };

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => {
      return {
        ...data,
        ...partialData,
      };
    },
    { isDeleteModalOpen: false, isRunPlanModalOpen: false }
  );
  const history = useHistory();
  const openPlanList = () => history.push(ROUTES.plans.index);

  return (
    <>
      <PopoverControls
        disabled={noPlanId}
        size={size}
        items={[
          {
            label: `Run Plan Now`,
            onClick: () => updateState({ isRunPlanModalOpen: true }),
            disabled: !isPlanActive,
          },
          {
            label: `Delete Plan`,
            onClick: () => updateState({ isDeleteModalOpen: true }),
          },
        ]}
      />
      <When condition={state.isRunPlanModalOpen}>
        <RunPlan
          planId={planId}
          planName={planName}
          isOpen={state.isRunPlanModalOpen}
          onClose={() => updateState({ isRunPlanModalOpen: false })}
        />
      </When>
      <When condition={state.isDeleteModalOpen}>
        <DeletePlan
          planId={planId}
          isDeleteModalOpen={state.isDeleteModalOpen}
          onClose={() => updateState({ isDeleteModalOpen: false })}
          onSuccess={openPlanList}
        />
      </When>
    </>
  );
}
