/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { BindingRolesDelete } from "./BindingRolesDelete";
import { UserEdit } from "./UserEdit";

type Props = {
  roleIds: string[];
  email: string;
};

export function Actions({ roleIds, email }: Props) {
  const prepareIds = roleIds.map((role) => `user__${email}__${role}`);
  return (
    <>
      <UserEdit roleIds={roleIds} email={email} />
      <BindingRolesDelete
        roleIds={prepareIds}
        onSuccess={() => {
          showSuccess(generateActionSuccessText(`User`)()("removed")());
        }}
      />
    </>
  );
}
