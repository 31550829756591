/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box, theme } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionAttempt,
  EventActionResourceState,
  Maybe,
} from "~/generated/graphql";
import { EventResourceGroupSection } from "~/components/resourceGroup/EventResourceGroupSection";
import { ResourceGroupsStates } from "~/views/events/EventsDetails/types";
import { ActionSettingsSection } from "./components/ActionSettingsSection";
import { EventResourceHeader } from "./components/EventResourceHeader";

type Props = {
  eventAction: EventAction;
  resourceStates?: Maybe<EventActionResourceState[]>;
  planId?: string;
  attempt?: EventActionAttempt;
};

export function EventActionBox({
  eventAction,
  resourceStates,
  planId,
  attempt,
}: Props) {
  const states = resourceStates?.reduce(
    (result: ResourceGroupsStates, state: EventActionResourceState) => {
      const rgId = state.resourceGroupId;
      return {
        ...result,
        [rgId]: result[rgId] ? [...result[rgId], state] : [state],
      };
    },
    {}
  );
  return (
    <Row>
      <Col
        xs={4}
        sm={4}
        md={4}
        lg={8}
        css={{ paddingRight: theme.spacing.spacing01 }}
      >
        <Box boxStyle="grey" innerSpacing="spacing00">
          <EventResourceHeader eventAction={eventAction} attempt={attempt} />
          <EventResourceGroupSection
            states={states}
            eventAction={eventAction}
          />
        </Box>
      </Col>
      <Col xs={4} sm={4} md={4} lg={4}>
        <Box innerSpacing="spacing00" css={{ height: "100%" }}>
          <ActionSettingsSection eventAction={eventAction} {...{ planId }} />
        </Box>
      </Col>
    </Row>
  );
}
