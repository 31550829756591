/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { ExtendedTooltip, FlexContainer, SVGIcon } from "@nordcloud/gnui";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import { ROUTES } from "~/routing/routes";
import { Actions } from "~/views/plans/PlanDetails/components/Actions";
import {
  FlexColumnMax,
  MediumColumn,
  MediumIconColumn,
  SmallIconColumn,
} from "../styles";
import { NextDateCronCell } from "./NextDateCronCell";

export type Plan = {
  id: string;
  name: string;
  scheduleCron: string;
  scheduleTimezone: string;
  enabled: boolean;
};

const columnHelper = createColumnHelper<Plan>();

export function columns() {
  return [
    columnHelper.accessor(() => null, {
      id: "color",
      header: () => null,
      cell: ({ row }) => {
        return (
          <FlexContainer justifyContent="center" alignItems="center">
            <ExtendedTooltip
              status={row.original.enabled ? "success" : "danger"}
              caption={row.original.enabled ? "Active" : "Not Active"}
            >
              <FlexContainer justifyContent="center" alignItems="center">
                <SVGIcon
                  color={row.original.enabled ? "success" : "danger"}
                  size="md"
                  name={row.original.enabled ? "statusSuccess" : "statusError"}
                />
              </FlexContainer>
            </ExtendedTooltip>
          </FlexContainer>
        );
      },
      meta: MediumIconColumn,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "Plan Name",
      cell: ({ row }) => (
        <NameWithLink
          name={row.original.name}
          link={ROUTES.plans.generateDetails(row.original.id)}
        />
      ),
      meta: FlexColumnMax,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "Next Execution (local time)",
      cell: ({ row }) => {
        return NextDateCronCell({
          cronExpression: row.original.scheduleCron,
          timezone: row.original.scheduleTimezone,
        });
      },
      meta: MediumColumn,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "Next Execution (plan time)",
      cell: ({ row }) => {
        return NextDateCronCell({
          cronExpression: row.original.scheduleCron,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      },
      meta: MediumColumn,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "",
      id: "actions",
      cell: ({ row }) => (
        <Actions
          planId={row.original.id}
          planName={row.original.name}
          isPlanActive={row.original.enabled}
        />
      ),
      meta: SmallIconColumn,
    }),
  ] as never;
}
