/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller } from "react-hook-form";
import { FlexContainer, Radio, Text, theme } from "@nordcloud/gnui";
import { RadioLabel } from "~/components/Styled";
import { Schedule } from "~/views/plans/types";

const scheduleTyp = [
  {
    name: "Repeated",
    label: "Specify the time and intervals to run the plan.",
    scope: Schedule.repeated,
    disabled: false,
  },
  {
    name: "Run Once on Time",
    label: "The plan will run automatically on the specified date and time.",
    scope: Schedule.runOnce,
    disabled: true,
  },
  {
    name: "Run Manually",
    label: "You can run the plan later.",
    scope: Schedule.runManually,
    disabled: true,
  },
];

type Props = {
  onScopeChange: (type: Schedule) => void;
};

export function SelectSchedule({ onScopeChange }: Props) {
  return (
    <>
      <FlexContainer
        justifyContent="space-between"
        alignItems="flex-start"
        mb={theme.spacing.spacing03}
      >
        {scheduleTyp.map(({ name, label, scope, disabled }) => (
          <RadioCheckbox
            key={name}
            name={name}
            label={label}
            scope={scope}
            onScopeChange={onScopeChange}
            disabled={disabled}
          />
        ))}
      </FlexContainer>
    </>
  );
}

type RadioCheckboxProps = {
  name: string;
  label: string;
  scope: Schedule;
  onScopeChange: (type: Schedule) => void;
  disabled: boolean;
};

export function RadioCheckbox({
  name,
  label,
  scope,
  onScopeChange,
  disabled,
}: RadioCheckboxProps) {
  return (
    <div
      css={{
        width: "50%",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <RadioLabel htmlFor={name}>
        <FlexContainer direction="column">
          <Controller
            name="scheduleType"
            render={({ field: { onChange } }) => {
              return (
                <Radio
                  id={name}
                  labelText={name}
                  value={scope}
                  defaultChecked={!disabled}
                  onChange={(e) => {
                    onScopeChange(scope);
                    onChange(e.target.value);
                  }}
                  disabled={disabled}
                />
              );
            }}
          />
          <Text
            tag="span"
            size="sm"
            ml="1.75rem"
            css={{
              marginTop: `-${theme.spacing.spacing03}`,
            }}
          >
            {label}
          </Text>
        </FlexContainer>
      </RadioLabel>
    </div>
  );
}
