/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { ChangeDetails } from "~/components/ChangeDetails";
import { Details } from "~/components/Details";
import { NoData } from "~/components/NoData";
import { BorderWrapper } from "~/components/Styled";
import { isNil } from "~/tools";
import { usePlan } from "../PlanProvider";
import { CronDetails } from "./CronDetails";
import { PlanDetailsStatus } from "./PlanDetailsStatus";
import {
  DetailsColumnFirst,
  DetailsColumnSecond,
  HeaderPresetPlan,
} from "./presets";

export function PlanInfo() {
  const { plan } = usePlan();

  if (isNil(plan)) {
    return <NoData message="no data" />;
  }

  return (
    <>
      <Col xs={8} sm={8} md={5} lg={8}>
        <BorderWrapper
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PlanDetailsStatus status={plan.enabled} />
          <div css={{ width: "100%" }}>
            <Details details={HeaderPresetPlan(plan)} />
          </div>
        </BorderWrapper>
        <Row>
          <Col>
            <Details details={DetailsColumnFirst(plan)} />
          </Col>
          <Col css={{ minWidth: "50%", justifyContent: "space-between" }}>
            <Details details={DetailsColumnSecond(plan)} />
            <CronDetails />
          </Col>
        </Row>
      </Col>
      <Col xs={8} sm={8} md={3} lg={4}>
        <ChangeDetails details={plan.changeDetails} />
      </Col>
    </>
  );
}
