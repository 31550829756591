/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, Message, Text, theme } from "@nordcloud/gnui";
import { EventAction, ExecutionStatus } from "~/generated/graphql";
import { inflect, isNonEmpty } from "~/tools";
import { useEvent } from "../../EventsDetails/EventProvider";
import { RefetchOptions } from "../../helpers";
import { ApproveEvent } from "./ApproveEvent";

export function ApproveEventMessage() {
  const { event } = useEvent();

  const actions = event?.actions?.filter(
    (eventAction: EventAction) =>
      eventAction.globalState?.status === ExecutionStatus.ApprovalRequired
  );

  return (
    <When condition={isNonEmpty(actions)}>
      <Message
        color={theme.color.support.purple}
        borderColor={theme.color.support.purple}
        image="warning"
        css={{
          marginBottom: theme.spacing.spacing02,
          paddingBottom: theme.spacing.spacing04,
        }}
      >
        <div>
          <Text
            mb={theme.spacing.spacing02}
            weight="bold"
            color={theme.color.support.purple}
          >
            This event is pending approval
          </Text>
          <Text color={theme.color.support.purple}>
            This event has unapproved {inflect("action")(actions?.length ?? 0)}.
          </Text>
          <FlexContainer>
            <ApproveEvent
              actions={actions}
              eventId={event?.id}
              refetchQueries={RefetchOptions.EVENT}
            />
          </FlexContainer>
        </div>
      </Message>
    </When>
  );
}
