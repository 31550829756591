/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Group, UpdateGroupInput } from "~/generated/graphql";
import { ResourceGroupFields } from "./types";

export function mapResourceGroupToResourceGroupPutInput(
  resourceGroup?: Partial<Group>
): UpdateGroupInput {
  return {
    id: resourceGroup?.id ?? "",
  };
}

export function getUpdateObject(
  data: ResourceGroupFields | UpdateGroupInput,
  resourceGroup?: Partial<Group>
): UpdateGroupInput {
  const putResourceGroup =
    mapResourceGroupToResourceGroupPutInput(resourceGroup);

  return { ...putResourceGroup, ...data };
}
