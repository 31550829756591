/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useHistory } from "react-router-dom";
import { Button, Modal, theme } from "@nordcloud/gnui";
import { ModalFooter } from "~/components/Styled";
import { ROUTES } from "~/routing/routes";
import { useTriggerPlan } from "~/views/plans/hooks/useTriggerPlan/useTriggerPlan";

type Props = {
  isOpen: boolean;
  planName: string;
  planId: string;
  onClose: () => void;
};

export function RunPlan({ isOpen, onClose, planId, planName }: Props) {
  const history = useHistory();
  const [triggerPlan, { loading }] = useTriggerPlan({
    onSuccess: (id: string) => {
      history.push(ROUTES.events.generateDetails(id));
    },
  });

  const onRunPlanNow = async () => {
    await triggerPlan(planId);
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      actionLabel="Run Plan"
      contentLabel="Run Plan Now"
    >
      {`Are you sure you want run ${planName} plan?`}
      <ModalFooter
        mt={theme.spacing.spacing02}
        justifyContent="flex-end"
        gap={theme.spacing.spacing03}
      >
        <Button severity="low" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          initialState={loading ? "loading" : ""}
          onClick={onRunPlanNow}
        >
          Run
        </Button>
      </ModalFooter>
    </Modal>
  );
}
