/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, Box, theme, ExtendedTooltip, Text } from "@nordcloud/gnui";

type Props = {
  status?: boolean;
};

export function PlanDetailsStatus({ status }: Props) {
  const colorContext = status ? "success" : "danger";
  return (
    <>
      <Wrap>
        <ExtendedTooltip
          status={colorContext}
          caption={
            <Text tag="span" size="sm" color={theme.color.text.text04}>
              {status ? "Active" : "Not Active"}
            </Text>
          }
          position="start"
        >
          <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
            <SVGIcon
              size="lg"
              color={colorContext}
              name={status ? "statusSuccess" : "statusError"}
            />
          </Box>
        </ExtendedTooltip>
      </Wrap>
    </>
  );
}

const Wrap = styled.div`
  margin-right: ${theme.spacing.spacing03};
`;
