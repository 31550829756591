/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Box,
  Input,
  FlexContainer,
  Switch,
  Text,
  Button,
  theme,
  Label,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components/Forms";
import { Email } from "../../formConfig";
import { InputHeadline, SwitchLabel } from "../styles";
import { NotificationGroupType } from "../types";
import { EmailAddMultiple } from "./EmailAddMultiple";

type Props = {
  onTypeChange: (
    isChecked: boolean,
    channelType: NotificationGroupType
  ) => void;
  types: NotificationGroupType[];
};

export function EmailForm({ types, onTypeChange }: Props) {
  const { control, formState, register } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "email_channels",
  });
  const handleAddEmails = (emails: Email[]) => {
    append(emails);
  };

  const error = formState.errors[`email_channels`] as FieldValues[] | undefined;

  const isSelected =
    types.find((t) => t === NotificationGroupType.email) ===
    NotificationGroupType.email;

  return (
    <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
      <FlexContainer>
        <Switch
          onChange={(e) =>
            onTypeChange(e.target.checked, NotificationGroupType.email)
          }
          checked={isSelected}
        />

        <SwitchLabel>
          <Text tag="div">Email</Text>
          <Text tag="div" size="sm" color={theme.color.text.text02}>
            Type an email address where the notification should be sent.
          </Text>
        </SwitchLabel>
      </FlexContainer>
      <When condition={isSelected}>
        <InputHeadline size="sm" tag="div">
          <Label
            name="Email Address"
            required
            css={{ color: theme.color.text.text02 }}
          />
        </InputHeadline>
        {fields.map((field, index) => {
          return (
            <FlexContainer
              key={field.id}
              css={{ borderTop: theme.borders.grey }}
              alignItems="flex-start"
            >
              <FormGroup
                error={error?.[index]?.email}
                customCss={{
                  padding: `${theme.spacing.spacing05} ${theme.spacing.spacing03} 0`,
                  width: "100%",
                }}
              >
                <Input
                  aria-label="provide email"
                  placeholder="e.g. john.smith@example.com"
                  {...register(`email_channels.${index}.email` as const)}
                />
              </FormGroup>
              <Button
                onClick={() => remove(index)}
                disabled={fields.length === 1}
                severity="low"
                icon="trash"
                mt={theme.spacing.spacing05}
              />
            </FlexContainer>
          );
        })}
        <FlexContainer
          gap={theme.spacing.spacing04}
          mt={theme.spacing.spacing07}
        >
          <Button
            severity="low"
            icon="plus"
            type="button"
            onClick={() => append({ email: "" })}
          >
            Add Email
          </Button>
          <EmailAddMultiple onSubmit={handleAddEmails} />
        </FlexContainer>
      </When>
    </Box>
  );
}
