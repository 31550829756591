// Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
import { Group } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";
import { stringRequired } from "~/components/Forms";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { useUpdateResourceGroup } from "../hooks/useUpdateResourceGroup/useUpdateResourceGroup";

const successMessage = () =>
  showSuccess(generateActionSuccessText("Resource Group")()("updated")());

export const HeaderPreset: (resourceGroup?: Partial<Group>) => DetailData[] = (
  resourceGroup
) => [
  {
    label: "Resource Group Name",
    value: resourceGroup?.name ?? resourceGroup?.id,
    editProps: {
      property: "name",
      updateHook: useUpdateResourceGroup,
      schema: {
        name: stringRequired("Name"),
      },
      onSuccess: successMessage,
    },
  },
];

export const DetailsFirstPreset: (
  resourceGroup?: Partial<Group>
) => DetailData[] = (resourceGroup) => [
  {
    label: "Resource Group ID",
    value: resourceGroup?.id,
    clipboard: true,
  },
  {
    label: "Description",
    value: resourceGroup?.description,
    editProps: {
      property: "description",
      updateHook: useUpdateResourceGroup,
      schema: {
        description: stringRequired("Description"),
      },
      onSuccess: successMessage,
    },
  },
];
