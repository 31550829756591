/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum Period {
  week = "WEEK",
  month = "MONTH",
  year = "YEAR",
  range = "RANGE",
}
export enum EventType {
  pastCurrent = "PAST_CURRENT",
  future = "FUTURE",
}

export enum EventsFilterFields {
  startDate = "startDate",
  endDate = "endDate",
}

export type EventsFiltersType = {
  startDate?: string;
  endDate?: string;
};

export type DateRange = {
  startDate: string;
  endDate: string;
};
