/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import styled from "styled-components";
import { Button, Checkbox, Table, Text, theme } from "@nordcloud/gnui";
import { columns } from "../constants";
import { StateItem, UpdatedGroup } from "../types";

const formatText = (s: string) =>
  s.charAt(0).toUpperCase() + s.toLowerCase().slice(1).replace("_", "\u00a0");

type Props = {
  state: StateItem[];
  onUpdateRow: (index: number, updatedGroup: UpdatedGroup) => void;
  onUpdateCol: (index: number, updatedGroup: UpdatedGroup) => void;
  onUpdate: (index: number, updatedGroup: UpdatedGroup) => void;
};

export function PermissionSelect({
  state,
  onUpdate,
  onUpdateRow,
  onUpdateCol,
}: Props) {
  return (
    <>
      <StyledTable hoverline>
        <StyledThead>
          <Table.tr>
            <StyledTh />
            <StyledTh />
            {columns.map((item) => (
              <StyledTh key={item}>
                <Text tag="span">{formatText(item)}</Text>
              </StyledTh>
            ))}
          </Table.tr>
          <Table.tr>
            <StyledTh>
              <Text tag="span" />
            </StyledTh>
            <StyledTh />
            {columns.map((item, idx) => {
              const isNotAll = state.some((row) =>
                row.checked.every((el) => el !== item)
              );

              const updateGroup = {
                name: item,
                checked: "",
              };
              const handleSelectAll = () => onUpdateCol(idx, updateGroup);
              return (
                <StyledTh key={item}>
                  <SelectAllBtn type="button" onClick={handleSelectAll}>
                    {isNotAll ? "Select All" : "Unselect All"}
                  </SelectAllBtn>
                </StyledTh>
              );
            })}
          </Table.tr>
        </StyledThead>
        <Table.tbody>
          {state.map((group: StateItem, index: number) => (
            <CheckboxGroupRow
              index={index}
              key={group.name}
              {...group}
              onUpdateRow={onUpdateRow}
              onUpdate={(updatedGroup: UpdatedGroup) =>
                onUpdate(index, updatedGroup)
              }
            />
          ))}
        </Table.tbody>
      </StyledTable>
    </>
  );
}

type CheckboxGroupRowProps = {
  name: string;
  index: number;
  checked: string[];
  onUpdateRow: (index: number, updatedGroup: UpdatedGroup) => void;
  onUpdate: (updatedGroup: UpdatedGroup) => void;
};

function CheckboxGroupRow({
  name,
  index,
  checked,
  onUpdateRow,
  onUpdate,
}: CheckboxGroupRowProps) {
  const handleSelectAll = () =>
    onUpdateRow(index, {
      name,
      checked: "",
    });

  const isAll = columns.every((col) => checked.includes(col));

  return (
    <StyledTr>
      <StyledTd>
        <Text tag="span">{formatText(name)}</Text>
      </StyledTd>
      <StyledTd>
        <SelectAllBtn type="button" onClick={handleSelectAll}>
          {isAll ? "Unselect All" : "Select All"}
        </SelectAllBtn>
      </StyledTd>
      {columns.map((item) => (
        <StyledTd key={`${item}_${name}`}>
          <Checkbox
            aria-label={`${item}_${name}`}
            withoutLabel
            value={item}
            checked={checked.includes(item)}
            onChange={() => onUpdate({ name, checked: item })}
          />
        </StyledTd>
      ))}
    </StyledTr>
  );
}

const StyledTd = styled(Table.td)`
  text-align: center;

  > div {
    justify-content: center;
  }
`;

const StyledTh = styled(Table.th)`
  text-align: center;
  border-bottom: none;
`;

const SelectAllBtn = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: ${theme.fontSizes.sm};
  color: ${theme.color.interactive.link};
  background: none;
  border: none;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing02};
  cursor: pointer;

  > span {
    text-align: center;
    display: block;
    width: 4rem;
    padding: 0;
  }

  &:hover {
    background: none;
    color: ${theme.color.interactive.infoHover};
  }
`;

const StyledTr = styled(Table.tr)`
  &:nth-child(odd):hover {
    cursor: auto;
  }
`;
const StyledThead = styled(Table.thead)`
  tr th {
    padding-bottom: ${theme.spacing.spacing01};
  }
`;

const StyledTable = styled(Table)`
  thead tr th {
    padding: ${theme.spacing.spacing01} 0;
    vertical-align: middle;
    span {
      text-transform: capitalize;
    }

    &:first-child {
      text-align: left;
      padding: ${theme.spacing.spacing02};
    }
  }
  tbody tr td {
    padding: ${theme.spacing.spacing02} ${theme.spacing.spacing02};
    vertical-align: middle;
    span {
      text-transform: capitalize;
    }

    &:first-child {
      text-align: left;
      padding: ${theme.spacing.spacing02};
    }
  }
`;
