/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useState } from "react";
import { When } from "react-if";
import { FlexContainer, Tab, Tabs as TabContainer } from "@nordcloud/gnui";
import { ActionScope } from "~/generated/graphql";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";
import { useStepState } from "../StepProvider";
import { ActionResourceGroups } from "./ActionResourceGroups";
import { ActionSettingsForm } from "./ActionSettingsForm";
import { AdvancedSettingsForm } from "./AdvancedSettingsForm";
import { NotificationForm } from "./NotificationForm";

type Props = {
  nextStep?: () => void;
};

export function ActionView({ nextStep }: Props) {
  const [tab, setTab] = useState(0);
  const {
    stepState: { selectedAction },
    updateStepState,
  } = useStepState();

  const handleTab = useCallback(
    (key: number) => {
      setTab(key);
      updateStepState({
        selectedAction: { ...selectedAction, selectedTab: key },
      });
    },
    [selectedAction, updateStepState]
  );

  return (
    <>
      <When condition={selectedAction?.scope === ActionScope.Resource}>
        <ActionResourceGroups />
      </When>
      <FlexContainer css={{ flex: 1 }} alignItems="stretch">
        <TabContainer step={tab} handleTab={handleTab}>
          <Tab
            label={<TabIcon caption="Action Settings" icon="settings" />}
            width="auto"
          >
            <ActionSettingsForm {...{ nextStep }} setTab={handleTab} />
          </Tab>
          <Tab
            label={
              <TabIcon caption="Advanced Action Settings" icon="doubleGear" />
            }
            width="auto"
          >
            <AdvancedSettingsForm {...{ nextStep }} setTab={handleTab} />
          </Tab>
          <Tab
            label={<TabIcon caption="Notification Settings" icon="bell" />}
            width="auto"
          >
            <NotificationForm {...{ nextStep }} />
          </Tab>
        </TabContainer>
      </FlexContainer>
    </>
  );
}
