/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { FlexContainer, Tag } from "@nordcloud/gnui";
import { RoleBindingByUser } from "~/generated/graphql";
import {
  ActionsColumn,
  FlexColumnLarge,
  FlexColumn,
  MediumColumn,
} from "~/components/Tables/styles";
import { dateFormat } from "~/constants";
import { Actions } from "./Actions";

const columnHelper = createColumnHelper<RoleBindingByUser>();

export function usersColumns() {
  return [
    columnHelper.accessor((row) => row?.subject, {
      header: "User Name",
      cell: (cell) => (
        <FlexContainer justifyContent="flex-start" alignItems="center">
          {cell.getValue()}
        </FlexContainer>
      ),
      meta: FlexColumn,
    }),
    columnHelper.accessor((row) => row?.roles, {
      header: "Roles",
      cell: ({ row }) => (
        <div>
          {row.original.roles?.map((role) => (
            <div key={role?.id}>
              <Tag text={role?.name} />
            </div>
          ))}
        </div>
      ),
      meta: FlexColumnLarge,
    }),
    columnHelper.accessor((row) => row?.roles, {
      header: "Added",
      cell: ({ row }) => {
        const flatDates = row.original.roles.map(
          (role) => role.changeDetails.createdAt
        );
        const addedDate = firstDate(flatDates);

        return <div>{dayjs(addedDate).format(dateFormat.dayMonthYear)}</div>;
      },
      meta: MediumColumn,
    }),
    columnHelper.accessor((row) => row?.roles, {
      header: "Updated",
      cell: ({ row }) => {
        const flatDates = row.original.roles.map(
          (role) => role.changeDetails.updatedAt
        );
        const updatedDate = lastDate(flatDates);

        return <div>{dayjs(updatedDate).format(dateFormat.dayMonthYear)}</div>;
      },
      meta: MediumColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const roleIds = row.original.roles.map((r) => r.id);
        return (
          <FlexContainer justifyContent="space-around">
            <Actions roleIds={roleIds} email={row.original.subject} />
          </FlexContainer>
        );
      },
      meta: ActionsColumn,
    }),
  ] as never;
}

const firstDate = (dates: string[]) => {
  return dates.reduce(
    (c: string, n: string) => (Date.parse(n) < Date.parse(c) ? n : c),
    `${new Date()}`
  );
};
const lastDate = (dates: string[]) => {
  return dates.reduce(
    (c: string, n: string) => (Date.parse(n) > Date.parse(c) ? n : c),
    `${new Date()}`
  );
};
