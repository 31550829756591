/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, Text } from "@nordcloud/gnui";
import { IncomingEventStatus, PlanAction } from "~/generated/graphql";
import { NoData } from "~/components";
import { ToggleItemBox } from "~/components/ToggleItemBox/ToggleItemBox";
import { isNonEmpty } from "~/tools";
import { getActionStatus } from "~/views/events/helpers";
import { useEvent } from "../../EventProvider";
import { IncomingEventActionBox } from "./components/ActionBox/IncomingEventActionBox";

type Props = {
  status: IncomingEventStatus;
};

export function IncomingEventDetailsTab({ status }: Props) {
  const { event } = useEvent();

  const actions = event?.plan?.planActions ?? [];
  const [planAction, setPlanAction] = useState<PlanAction>(actions[0]);

  return (
    <Box innerSpacing="spacing00">
      <If condition={isNonEmpty(actions)}>
        <Then>
          <Row>
            <Col xs={4} sm={8} md={3} lg={3}>
              <FlexContainer direction="column" justifyContent="flex-start">
                <Text width="100%" align="left">
                  Actions
                </Text>
                {actions?.map((item: PlanAction) => (
                  <ToggleItemBox
                    key={item.id}
                    item={{
                      id: item.id,
                      icon: getActionStatus(status)?.icon,
                      iconColor: getActionStatus(status)?.color(),
                      tooltip: getActionStatus(status)?.description,
                      color: item.action.color,
                      name: item.name,
                      onMouseDown: () => setPlanAction(item),
                    }}
                    active={item.id === planAction.id}
                  />
                ))}
              </FlexContainer>
            </Col>
            <Col xs={4} sm={8} md={5} lg={9}>
              <Box boxStyle="grey" innerSpacing="spacing04">
                <FlexContainer alignItems="flex-start">
                  <IncomingEventActionBox
                    planAction={planAction}
                    planId={event?.plan?.id}
                    status={status}
                  />
                </FlexContainer>
              </Box>
            </Col>
          </Row>
        </Then>
        <Else>
          <NoData hasIcon message="There are no Actions" />
        </Else>
      </If>
    </Box>
  );
}
