/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Box,
  Input,
  FlexContainer,
  Switch,
  Text,
  Button,
  theme,
  Label,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components/Forms";
import { InputHeadline, SwitchLabel } from "../styles";
import { NotificationGroupType } from "../types";

type Props = {
  onTypeChange: (
    isChecked: boolean,
    channelType: NotificationGroupType
  ) => void;
  types: NotificationGroupType[];
};

export function SlackForm({ types, onTypeChange }: Props) {
  const { control, formState, register } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "slack_channels",
  });

  const error = formState.errors[`slack_channels`] as FieldValues[] | undefined;

  const isSelected =
    types.find((t) => t === NotificationGroupType.slack) ===
    NotificationGroupType.slack;
  return (
    <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
      <FlexContainer>
        <Switch
          onChange={(e) =>
            onTypeChange(e.target.checked, NotificationGroupType.slack)
          }
          checked={isSelected}
        />
        <SwitchLabel>
          <Text tag="div">Slack</Text>
          <Text tag="div" size="sm" color={theme.color.text.text02}>
            You can send notifications through a Slack Channel by adding the
            (Webhook) URL.
          </Text>
        </SwitchLabel>
      </FlexContainer>
      <When condition={isSelected}>
        <InputHeadline size="sm" tag="div">
          <FlexContainer>
            <Label
              name="Webhook URL"
              required
              css={{ color: theme.color.text.text02 }}
            />
          </FlexContainer>
        </InputHeadline>
        {fields.map((field, index) => {
          return (
            <FlexContainer
              key={field.id}
              css={{ borderTop: theme.borders.grey }}
              alignItems="flex-start"
            >
              <FormGroup
                error={error?.[index]?.webhookUrl}
                customCss={{
                  padding: `${theme.spacing.spacing05} ${theme.spacing.spacing03} 0`,
                  width: "100%",
                }}
              >
                <Input
                  key={field.id}
                  aria-label="provide webhook url"
                  placeholder="e.g. https://hooks.slack.com/services/T12345678/B12345678/abcdefgh12345678 "
                  {...register(`slack_channels.${index}.webhookUrl` as const)}
                />
              </FormGroup>
              <Button
                onClick={() => remove(index)}
                disabled={fields.length === 1}
                severity="low"
                icon="trash"
                mt={theme.spacing.spacing05}
              />
            </FlexContainer>
          );
        })}
        <FlexContainer mt={theme.spacing.spacing07}>
          <Button
            severity="low"
            icon="plus"
            type="button"
            onClick={() => append({ webhookUrl: "" })}
          >
            Add Channel
          </Button>
        </FlexContainer>
      </When>
    </Box>
  );
}
