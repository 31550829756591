/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, ModalConfirm } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { useScanCloudAccount } from "~/views/accounts/hooks/useScanCloudAccount/useScanCloudAccount";

type Props = {
  accountId: string;
};

export function ForceScan({ accountId }: Props) {
  const {
    isOpen: isForceScanModalOpen,
    close: closeForceScanModal,
    open: openForceScanModal,
  } = useDisclosure(false);

  const [scanCloudAccount] = useScanCloudAccount({
    onSuccess: () =>
      showSuccess(
        generateActionSuccessText("Scan Cloud Account")()("started")()
      ),
  });

  const onForceScanning = async () => {
    closeForceScanModal();
    await scanCloudAccount(accountId);
  };

  return (
    <>
      <Button icon="refresh" severity="low" onClick={openForceScanModal}>
        Force Scanning
      </Button>
      <ModalConfirm
        onClose={closeForceScanModal}
        isOpen={isForceScanModalOpen}
        confirm={onForceScanning}
        actionLabel="Scan Now"
        contentLabel="Scan Cloud Account?"
      >
        Are you sure you want to scan Cloud Account now?
      </ModalConfirm>
    </>
  );
}
