/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExecutionPolicy, SkipWindowType } from "~/generated/graphql";
import { FormData } from "./formConfig";
import { FormField, TimeUnits } from "./types";

export const maxInAdvance = 2147483647;

export enum PlanField {
  DETAILS = "details",
  SCHEDULE_PLAN = "schedule_plan",
  PLAN_SETTINGS = "plan_settings",
  GENERAL_NOTIFICATIONS = "general_notifications",
  ERRORS = "plan_errors",
}

export enum PlanErrors {
  PLAN_ACTION_NAMES = "action names",
  PLAN_NOTIFICATION_GROUPS = "action notifications",
}

export type PlanData = {
  [PlanField.DETAILS]: FormData | undefined;
  [PlanField.SCHEDULE_PLAN]: FormData | undefined;
  [PlanField.PLAN_SETTINGS]: FormData | undefined;
  [PlanField.GENERAL_NOTIFICATIONS]: FormData | undefined;
  [PlanField.ERRORS]?: PlanErrors[] | [];
};

export const defaultValues: PlanData = {
  [PlanField.DETAILS]: undefined,
  [PlanField.SCHEDULE_PLAN]: undefined,
  [PlanField.PLAN_SETTINGS]: undefined,
  [PlanField.GENERAL_NOTIFICATIONS]: undefined,
  [PlanField.ERRORS]: [],
};

export const defaultActionSettingsValues = {
  [FormField.UNIT]: TimeUnits.minutes,
  [FormField.EXECUTION_POLICY]: ExecutionPolicy.SuccessOrApproval,
  [FormField.RUN_IN_SEQUENCE]: false,
  [FormField.SKIP_WINDOW]: SkipWindowType.Never,
  [FormField.WINDOW_DURATION]: "1",
  [FormField.INPUT_PARAMETERS]: [],
};

export enum SystemActionNames {
  DELAY = "DELAY",
}
