/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "@nordcloud/gnui";
import { timezones } from "~/constants/timezones";
import { useGenerateDatesFromCron } from "~/views/plans/hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { PlanData, PlanField } from "../constants";
import { FormField, TimeUnits } from "../types";
import { convertToMinutes, isFormValid } from "./utils";

type Props = {
  timezone: string;
  expression: string;
  offset: number;
  offsetUnit: TimeUnits;
  setTimezone: Dispatch<SetStateAction<string>>;
  setCronNextDates: Dispatch<SetStateAction<string[]>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronTimezoneDropDown({
  timezone,
  expression,
  offset,
  offsetUnit,
  setTimezone,
  setPlanData,
  setCronNextDates,
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    setCronNextDates: setCronNextDates,
  });

  const handleCronDates = async (value: string) => {
    const offsetInMinutes = convertToMinutes(offset, offsetUnit);

    await generateDatesFromCron({
      cron_expression: expression,
      location: value,
      offset_in_minutes: offsetInMinutes,
    });

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_TIMEZONE]: value,
      },
    }));
  };

  const handleChange = (tz: string) => {
    setTimezone(tz);

    if (isFormValid(errors) && expression) {
      handleCronDates(tz);
    }
  };

  return (
    <Controller
      name={FormField.SCHEDULE_TIMEZONE}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Dropdown
          name="Select timezone"
          data-testid="dropdown-select-timezone"
          options={timezones}
          onChange={(tz: string) => {
            onChange(tz);
            handleChange(tz);
          }}
          value={timezone || value}
          minNumOfOptionsToShowSearchBox={10}
        />
      )}
    />
  );
}
