/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { When } from "react-if";
import { PopoverControls } from "~/components";
import { inflect, isEmpty } from "~/tools";
import { AddToResourceGroup } from "./AddToGroup/AddToResourceGroup";
import { DeleteResource } from "./DeleteResource/DeleteResource";

type State = {
  isDeleteModalOpen: boolean;
  isAddToGroupModalOpen: boolean;
};

type Props = {
  resourcesIds: string[];
  onSuccess?: () => void;
  size?: "large" | "small";
};

export function Actions({ resourcesIds, size = "small" }: Props) {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => {
      return {
        ...data,
        ...partialData,
      };
    },
    { isDeleteModalOpen: false, isAddToGroupModalOpen: false }
  );

  const noResourcesIds = isEmpty(resourcesIds ?? []);
  return (
    <>
      <PopoverControls
        disabled={noResourcesIds}
        size={size}
        items={[
          {
            label: `Delete ${inflect("Resource")(resourcesIds?.length)}`,
            onClick: () => updateState({ isDeleteModalOpen: true }),
          },
          {
            label: `Add to Resource Group`,
            onClick: () => updateState({ isAddToGroupModalOpen: true }),
          },
        ]}
      />
      <When condition={state.isDeleteModalOpen}>
        <DeleteResource
          {...{
            resourcesIds: resourcesIds,
            isDeleteModalOpen: state.isDeleteModalOpen,
          }}
          close={() => updateState({ isDeleteModalOpen: false })}
        />
      </When>
      <When condition={state.isAddToGroupModalOpen}>
        <AddToResourceGroup
          {...{
            resourcesIds: resourcesIds,
            isOpen: state.isAddToGroupModalOpen,
          }}
          close={() => updateState({ isAddToGroupModalOpen: false })}
        />
      </When>
    </>
  );
}
