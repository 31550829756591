/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Input, Label, Sidebar, Spacer, theme } from "@nordcloud/gnui";
import {
  RbacRoleBindingType,
  ApiKey,
  UpdateApiKeyInput,
} from "~/generated/graphql";
import { FormGroup, stringRequired } from "~/components/Forms";
import { dateFormat } from "~/constants";
import { useDisclosure } from "~/hooks";
import { useUpdateApiKey } from "~/views/permissions/APIKeys/hooks/useUpdateApiKey/useUpdateApiKey";
import { UserRolesSidebar } from "~/views/permissions/components/UserRolesSidebar";
import { useCreateRoleBinding } from "~/views/permissions/hooks/useCreateRoleBinding/useCreateRoleBinding";
import { useDeleteRoleBinding } from "~/views/permissions/hooks/useDeleteRoleBinding/useDeleteRoleBinding";

export const schema = z.object({
  id: stringRequired("id"),
  name: stringRequired("name"),
});

type State = {
  initRolesIds: string[];
  rolesIds: string[];
  apiKey: ApiKey;
  expires: string;
};

type ApiKeysEditProps = {
  apiKey: ApiKey;
  initRolesIds: string[];
};

export function ApiKeysEditSidebar({ apiKey, initRolesIds }: ApiKeysEditProps) {
  const unifiedIds = initRolesIds.map((id) => id.split("__")[2]);

  const { isOpen, open, close } = useDisclosure();

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    {
      apiKey: apiKey,
      initRolesIds: unifiedIds ?? [],
      rolesIds: unifiedIds ?? [],
      expires: "",
    }
  );

  const { handleSubmit, register, formState, reset, clearErrors } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      id: state.apiKey.id,
      name: state.apiKey.name,
    },
  });

  const [deleteRoleBinding, { loading: loadingDelete }] = useDeleteRoleBinding({
    onSuccess: () => null,
  });

  const [createRoleBinding, { loading: loadingCreate }] = useCreateRoleBinding({
    onSuccess: () => null,
  });

  type BindingProps = {
    prevRoles: string[];
    currentRoles: string[];
  };

  const deleteDiffRoleBindings = async ({
    prevRoles,
    currentRoles,
  }: BindingProps) => {
    await Promise.all(
      prevRoles.map((id) => {
        if (!currentRoles.includes(id)) {
          return deleteRoleBinding(id);
        }
      })
    );
  };

  const createDiffRoleBindings = async ({
    prevRoles,
    currentRoles,
  }: BindingProps) => {
    await Promise.all(
      currentRoles.map((id) => {
        if (!prevRoles.includes(id)) {
          return createRoleBinding({
            roleId: id,
            subject: state.apiKey.id,
            type: RbacRoleBindingType.ApiKey,
          });
        }
      })
    );
  };

  const [updateApiKey, { loading }] = useUpdateApiKey({
    onSuccess: async () => {
      clean();
    },
  });

  const onSubmit = async (data: UpdateApiKeyInput) => {
    await deleteDiffRoleBindings({
      prevRoles: initRolesIds,
      currentRoles: state.rolesIds,
    });
    await createDiffRoleBindings({
      prevRoles: initRolesIds,
      currentRoles: state.rolesIds,
    });
    await updateApiKey(data);
  };

  const onSelectRole = (ids: string[]) => updateState({ rolesIds: ids });

  const clean = () => {
    reset();
    clearErrors();
    updateState({ expires: "" });
    close();
  };

  const isLoading = loading || loadingDelete || loadingCreate;

  return (
    <>
      <Button
        aria-label="edit button"
        icon="edit"
        size="md"
        severity="low"
        onClick={() => {
          open();
        }}
      />
      <Sidebar title="Edit API Key" isOpen={isOpen} onClick={clean}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={formState.errors["name"]}>
            <Label name="API Key Name" htmlFor="name" required />
            <input type="hidden" {...register("id")} value={state.apiKey.id} />
            <Input
              id="name"
              placeholder="API Key Name"
              {...register("name")}
              disabled={isLoading}
            />
          </FormGroup>
          <FormGroup>
            <Label name="Expires" htmlFor="expiresAt" />
            <Input
              placeholder="dd.mm.yyyy"
              value={dayjs(state.apiKey.expiresAt).format(
                dateFormat.dayMonthYear
              )}
              readOnly
              icon="calendar"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label name="User Role" htmlFor="userRole" />
            <UserRolesSidebar
              ids={state.rolesIds}
              onSelect={onSelectRole}
              isLoading={isLoading}
            />
          </FormGroup>
          <Spacer height={theme.spacing.spacing02} />
          <Button
            disabled={isLoading}
            initialState={isLoading ? "loading" : "success"}
          >
            Apply
          </Button>
        </form>
      </Sidebar>
    </>
  );
}
