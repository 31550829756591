/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { createCtx } from "~/tools";
import { PlanData } from "./constants";

type Props = {
  planData?: PlanData;
  setPlanData: Dispatch<SetStateAction<PlanData>>;
};

export const [usePlanWizard, PlanWizardCtxProvider] = createCtx<Props>();
