/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { ExecutionPolicy } from "~/generated/graphql";
import { DataDisplayWrapper } from "~/components";

type Props = {
  policy?: ExecutionPolicy;
};

export function DisplayExecutionPolicy({ policy }: Props) {
  return (
    <>
      <Text size="sm" tag="div">
        Action Execution Policy
      </Text>
      <DataDisplayWrapper hasData={policy != null}>
        <Text data-testid={"label-action-execution-policy"}>
          {mapExecutionPolicy(policy)}
        </Text>
      </DataDisplayWrapper>
    </>
  );
}

const mapExecutionPolicy = (policy: ExecutionPolicy | undefined) => {
  switch (policy) {
    case ExecutionPolicy.Approval:
      return "Approval";
    case ExecutionPolicy.Anyway:
      return "Run Anyway";
    case ExecutionPolicy.SuccessOrApproval:
      return "Success or Approval";
    default:
      return "N/A";
  }
};
