/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import { FlexContainer, Input, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { convertToMinutes } from "../../../PlanScheduleForms/utils";
import { FormField, TimeUnits } from "../../../types";
import { DropDownUnit } from "./DropDownUnit";

type Props = {
  control: Control;
  handleChange: (
    name: FormField,
    onChange: (value: string | boolean) => void,
    value: string | boolean
  ) => void;
};

export function WindowDuration({ control, handleChange }: Props) {
  const { setError } = useFormContext();
  const [durationUnit, setDurationUnit] = useState(TimeUnits.minutes);

  return (
    <FlexContainer mb={theme.spacing.spacing04} gap={theme.spacing.spacing03}>
      <div css={{ width: "10rem" }}>
        <Controller
          control={control}
          name={FormField.WINDOW_DURATION}
          render={({ field, formState }) => {
            const error = formState.errors[FormField.WINDOW_DURATION];

            return (
              <FormGroup error={error} customCss={{ position: "relative" }}>
                <Input
                  {...field}
                  value={field.value}
                  name={FormField.WINDOW_DURATION}
                  type="number"
                  id="activeWindowDuration"
                  onChange={(e) => {
                    const windowDuration = Number(e.target.value);

                    if (
                      windowDuration < MIN_DURATION ||
                      !Number.isInteger(windowDuration)
                    ) {
                      setError(FormField.WINDOW_DURATION, {
                        type: "min",
                        message: MIN_DURATION_MESSAGE,
                      });

                      return;
                    }

                    const windowDurationInMinutes = convertToMinutes(
                      windowDuration,
                      durationUnit
                    );

                    handleChange(
                      FormField.WINDOW_DURATION,
                      () => field.onChange(windowDurationInMinutes),
                      e.target.value
                    );
                  }}
                />
              </FormGroup>
            );
          }}
        />
      </div>
      <div css={{ width: "10rem" }}>
        <FormGroup>
          <DropDownUnit {...{ handleChange, setDurationUnit }} />
        </FormGroup>
      </div>
    </FlexContainer>
  );
}

const MIN_DURATION = 1;
const MIN_DURATION_MESSAGE =
  "Window duration must be a whole number greater than 0";
