/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then } from "react-if";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { EventAction } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ResourceGroupsStates } from "~/views/events/EventsDetails/types";
import { NoData } from "../NoData";
import { EventResourceGroupItem } from "./EventResourceGroupItem";

type Props = {
  states?: ResourceGroupsStates;
  eventAction: EventAction;
};

export function EventResourceGroupSection({ states, eventAction }: Props) {
  const { event } = useEvent();
  const { id: eventId } = event;

  const resourcesIds = states && Object.keys(states);

  const eventActionEndTime = eventAction?.globalState?.endTime
    ? dayjs(eventAction?.globalState?.endTime).format(
        dateFormat.shortDateWithTime
      )
    : undefined;

  return (
    <Wrap>
      <If condition={isNotEmpty(resourcesIds ?? [])}>
        <Then>
          {resourcesIds?.map((resource) => {
            const status =
              states && states[resource].map((group) => group.status);
            return (
              <EventResourceGroupItem
                key={resource}
                resourceGroupStatus={status}
                states={states ? states[resource] : []}
                eventId={eventId}
                {...{ eventActionEndTime }}
              />
            );
          })}
        </Then>
        <Else>
          <NoData
            hasIcon
            customCss={{ flexDirection: "column" }}
            message="There is no resource needed for this action"
          />
        </Else>
      </If>
    </Wrap>
  );
}

const Wrap = styled.div`
  & > :not(:first-child) {
    margin-top: ${theme.spacing.spacing04};
  }
`;
