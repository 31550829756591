/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldErrorsImpl } from "react-hook-form";
import { TimeUnits } from "../types";

export const convertToMinutes = (
  value: number | string,
  unit: TimeUnits
): number => {
  const numericValue = typeof value === "string" ? parseInt(value) : value;

  switch (unit) {
    case TimeUnits.hours: {
      return Math.floor(numericValue * 60);
    }
    case TimeUnits.days: {
      return Math.floor(numericValue * 24 * 60);
    }
    case TimeUnits.minutes: {
      return Math.floor(numericValue);
    }
    case TimeUnits.weeks: {
      return Math.floor(numericValue * 7 * 24 * 60);
    }

    default:
      return 0;
  }
};

export const isFormValid = (
  errors: Partial<FieldErrorsImpl<{ [x: string]: unknown }>>
): boolean => {
  return Object.keys(errors).length === 0;
};

export function adjustInvalidCronMessage(text: string) {
  return capitalizeFirstLetter(trimCronValidationMessage(text));
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function trimCronValidationMessage(text: string): string {
  const trimIndex = text.indexOf(".");

  return text.length > 200 && trimIndex !== -1
    ? text.slice(0, trimIndex + 1)
    : text;
}
