/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { DraggableProvided } from "react-beautiful-dnd";
import { ToggleItemBox } from "~/components";
import { Item } from "~/types";
import { SelectedAction } from "../../types";

type Props = {
  item: Item;
  onMouseDown: (item: SelectedAction) => void;
  onDelete?: () => void;
  active?: boolean;
  noDesc?: boolean;
  provided?: DraggableProvided;
};

export function ActionItem({
  item,
  onMouseDown,
  onDelete,
  active,
  noDesc,
  provided,
}: Props) {
  const description = noDesc ? "" : item.description || "";

  return (
    <ToggleItemBox
      active={active}
      provided={provided}
      item={{
        id: item.id ?? "",
        color: item.color ?? "",
        name: item.name ?? "",
        actionType: item.actionType,
        description,
        icon: item.icon,
        onMouseDown: () => onMouseDown(item),
        onDelete: onDelete,
      }}
    />
  );
}
