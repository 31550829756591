/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

type Params = {
  isData: boolean;
  loading: boolean;
  isError: boolean;
};

export function downloadButtonState({
  isData,
  loading,
  isError,
}: Params): string {
  if (loading) {
    return "loading";
  }
  if (isData) {
    return "success";
  }
  if (isError) {
    return "error";
  }
  return "download";
}
