/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Input, Label, Sidebar, Spacer, theme } from "@nordcloud/gnui";
import { RbacRoleBindingType } from "~/generated/graphql";
import { FormGroup, stringRequired } from "~/components/Forms";
import { useDisclosure } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty, isNotNil } from "~/tools";
import { UserRolesSidebar } from "~/views/permissions/components/UserRolesSidebar";
import { useCreateRoleBinding } from "~/views/permissions/hooks/useCreateRoleBinding/useCreateRoleBinding";
import { useDeleteRoleBinding } from "../../hooks/useDeleteRoleBinding/useDeleteRoleBinding";
import { FormData } from "../formConfig";

function refineRoles(el: string[]): boolean {
  return el.every((i) => i.length > 1);
}

export const schema = z.object({
  subject: stringRequired("Email"),
  roleId: z
    .string()
    .array()
    .refine((val) => refineRoles(val), { message: "Role is required" }),
});

type Props = {
  email: string;
  roleIds: string[];
};

export function UserEdit({ email, roleIds }: Props) {
  const { isOpen, open, close } = useDisclosure();

  const {
    handleSubmit,
    register,
    formState,
    clearErrors,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      subject: email,
      roleId: roleIds,
    },
  });

  const watcher = watch();

  const compareRole = roleIds.filter((role) => !watcher.roleId.includes(role));
  const prepareIds = compareRole.map((role) => `user__${email}__${role}`);

  const [deleteRoleBinding] = useDeleteRoleBinding({});

  const clean = () => {
    clearErrors();
    reset({
      subject: "",
      roleId: [""],
    });
    close();
    showSuccess(generateActionSuccessText("User")()("updated")());
  };

  const [createRoleBinding, { loading }] = useCreateRoleBinding({
    onSuccess: clean,
  });

  const onSubmit = (formData: FormData) => {
    if (isNotNil(formData.roleId)) {
      Promise.all(prepareIds.map((id) => deleteRoleBinding(id)))
        .then(() =>
          Promise.all(
            formData.roleId.map((id) =>
              createRoleBinding({
                roleId: id,
                subject: formData.subject ?? "",
                type: RbacRoleBindingType.User,
              })
            )
          )
        )
        .catch(() => showError());
    }
  };

  return (
    <>
      <Button
        aria-label="edit button"
        icon="edit"
        size="md"
        severity="low"
        onClick={open}
      />
      <Sidebar title="Edit User" isOpen={isOpen} onClick={close}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={formState.errors["subject"]}>
            <Label name="Email" htmlFor="subject" required />
            <Input disabled id="subject" {...register("subject")} />
          </FormGroup>
          <FormGroup error={formState.errors["roleId"]}>
            <Label name="User Role" htmlFor="roleId" required />
            <UserRolesSidebar
              ids={watcher.roleId.filter(isNotEmpty)}
              onSelect={(roleId: string[]) => setValue("roleId", roleId)}
              isLoading={loading}
            />
          </FormGroup>
          <Spacer height={theme.spacing.spacing02} />
          <Button
            disabled={loading}
            initialState={loading ? "loading" : "success"}
          >
            Apply
          </Button>
        </form>
      </Sidebar>
    </>
  );
}
