/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Else, If, Then } from "react-if";
import { Box, Tabs as TabContainer } from "@nordcloud/gnui";
import { StyledTab } from "~/components/Styled/Tabs";
import { isNonEmpty } from "~/tools";
import { useEvent } from "../EventProvider";
import { EventDetailsTab } from "./Details/EventDetailsTab";
import { IncomingEventDetailsTab } from "./Details/IncomingEventDetailsTab";
import { MetadataTab } from "./Metadata/Metadata";

export function Tabs() {
  const [currentStep, setCurrentStep] = useState(0);
  const { event } = useEvent();

  return (
    <Box spacing="spacing02" css={{ padding: 0 }}>
      <TabContainer step={currentStep} handleTab={setCurrentStep}>
        <StyledTab heading="Details">
          <If condition={isNonEmpty(event?.actions)}>
            <Then>
              <EventDetailsTab />
            </Then>
            <Else>
              <IncomingEventDetailsTab status={event.status} />
            </Else>
          </If>
        </StyledTab>
        <StyledTab heading="Metadata">
          <MetadataTab />
        </StyledTab>
      </TabContainer>
    </Box>
  );
}
