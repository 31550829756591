/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Table,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  EventActionResourceState,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { StyledTd } from "~/components/Tables/styles";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { isNotNil } from "~/tools";
import { EventResourceGroupAction } from "~/views/events/EventsDetails/Tabs/Details/components/ActionBox/components/EventResourceGroupAction";
import { OSCell } from "~/views/resources/components/OSCell";
import { getResourceStatus } from "./utils";

type Props = {
  state: EventActionResourceState;
  eventId: string;
};

export function EventResourceStateItem({ state, eventId }: Props) {
  const os = {
    subType: state.resource?.os?.subType ?? "",
    type: state.resource?.os?.type ?? "",
  };

  const isExecutionInProgress =
    state.status === ResourceExecutionStatus.InProgress ||
    state.status === ResourceExecutionStatus.Pending;

  const changeStatusDetails = {
    eventId: eventId,
    eventActionId: state.eventActionId,
    eventActionResourceStateId: state.id,
  };

  const isManualStatus = state.status === ResourceExecutionStatus.ManualSuccess;
  const status = getResourceStatus([state.status]).description;

  return (
    <Table.tr key={state.resource?.id ?? state.resourceId}>
      <StyledTd css={{ width: "2%" }}>
        <ExtendedTooltip
          caption={
            <If condition={isManualStatus}>
              <Then>
                <TooltipOverlay>
                  <div>Status:</div> <span>{status}</span>
                  <div>Changes made:</div>
                  <span>
                    {dayjs(state.changeDetails.updatedAt).format(
                      dateFormat.dayMonthYear
                    )}
                  </span>
                  <div>Changed by:</div>
                  <span>{state.changeDetails.updatedBy}</span>
                  <div>Comment:</div>
                  <span>{state.annotation}</span>
                </TooltipOverlay>
              </Then>
              <Else>{status}</Else>
            </If>
          }
          status={getResourceStatus([state.status]).tooltipColor}
          position="start"
        >
          <SVGIcon
            name={getResourceStatus([state.status]).icon}
            color={getResourceStatus([state.status]).color()}
          />
        </ExtendedTooltip>
      </StyledTd>
      <StyledTd css={{ width: "2%" }}>
        <OSCell os={os} />
      </StyledTd>
      <Table.td>
        <FlexContainer direction="column" alignItems="flex-start">
          <If condition={isNotNil(state.resource)}>
            <Then>
              <Link
                to={`${ROUTES.resources.generateDetails(
                  state.resource?.id ?? ""
                )}`}
              >
                {state.resource?.name ?? state.resourceId}
              </Link>
              <Text size="sm" color={theme.color.text.text02}>
                {state.resource?.name ?? state.resourceId}
              </Text>
            </Then>
            <Else>
              <ExtendedTooltip
                caption={"Resource has been deleted"}
                position="start"
              >
                <Text size="md" color={theme.color.text.text02}>
                  {state.resourceId}
                </Text>
              </ExtendedTooltip>
            </Else>
          </If>
        </FlexContainer>
      </Table.td>
      <StyledTd css={{ textAlign: "right" }}>
        <EventResourceGroupAction
          actionOutputUrl={state.outputUri}
          resourceName={state.resource?.name ?? state.resourceId}
          changeStatusDetails={changeStatusDetails}
          isInProgress={isExecutionInProgress}
        />
      </StyledTd>
    </Table.tr>
  );
}
const TooltipOverlay = styled.div`
  display: grid;
  grid-template-columns: 5.5rem 1fr;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
