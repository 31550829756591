/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "@nordcloud/gnui";
import { useGenerateDatesFromCron } from "~/views/plans/hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { PlanData, PlanField } from "../constants";
import { FormField, TimeUnits } from "../types";
import { adjustInvalidCronMessage, convertToMinutes } from "./utils";

type Props = {
  timezone: string;
  offset: number;
  offsetUnit: TimeUnits;
  setCronExpression: Dispatch<SetStateAction<string>>;
  setCronNextDates: Dispatch<SetStateAction<string[]>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronExpressionInput({
  timezone,
  offset,
  offsetUnit,
  setCronExpression,
  setCronNextDates,
  setPlanData,
}: Props) {
  const { control, setError, clearErrors } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    setCronNextDates: setCronNextDates,
  });

  const offsetInMinutes = convertToMinutes(offset, offsetUnit);

  const generateCronDates = async (value: string) => {
    const result = await generateDatesFromCron({
      cron_expression: value,
      location: timezone,
      offset_in_minutes: offsetInMinutes,
    });

    const invalidCronMessage =
      result.data?.generateDatesFromCron.validationMsg ||
      result.data?.generateDatesFromCron.error;

    if (invalidCronMessage) {
      setError(FormField.SCHEDULE_CRON, {
        message: adjustInvalidCronMessage(invalidCronMessage),
      });

      return;
    }

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_CRON]: value,
      },
    }));

    clearErrors(FormField.SCHEDULE_CRON);
  };

  const handleChange = (cronExpression: string) => {
    setCronExpression(cronExpression);

    if (timezone && cronExpression) {
      generateCronDates(cronExpression);
    }
  };

  return (
    <Controller
      name={FormField.SCHEDULE_CRON}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Input
          type="text"
          id={FormField.SCHEDULE_CRON}
          name={FormField.SCHEDULE_CRON}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
            handleChange(event.target.value);
          }}
          placeholder={CRON_INPUT_PLACEHOLDER}
        />
      )}
    />
  );
}

const CRON_INPUT_PLACEHOLDER = "Cron expression...";
