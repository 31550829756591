/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, theme } from "@nordcloud/gnui";
import { ActionScope } from "~/generated/graphql";
import { NoData, ResourceGroupSection } from "~/components";
import { isNotEmpty } from "~/tools";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";
import { ActionView } from "./components/ActionSettingsView";

export function ActionBox() {
  const { planAction } = usePlan();

  const isResourceSection = planAction?.action?.scope === ActionScope.Resource;

  return (
    <Row>
      <If condition={isResourceSection}>
        <Then>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={8}
            css={{ paddingRight: theme.spacing.spacing01 }}
          >
            <If condition={isNotEmpty(planAction?.resourceGroups ?? [])}>
              <Then>
                <Box boxStyle="grey" innerSpacing="spacing00">
                  <ResourceGroupSection
                    resourceGroups={planAction?.resourceGroups}
                  />
                </Box>
              </Then>
              <Else>
                <NoData
                  hasIcon
                  customCss={{ flexDirection: "column" }}
                  message={
                    <FlexContainer
                      justifyContent="center"
                      direction="column"
                      css={{ textAlign: "center" }}
                    >
                      There are no resource groups in the action. <br />
                      Please add resource groups.
                    </FlexContainer>
                  }
                />
              </Else>
            </If>
          </Col>
          <Col
            data-testid={"container-action-view"}
            xs={4}
            sm={4}
            md={4}
            lg={4}
          >
            <Box innerSpacing="spacing00" css={{ height: "100%" }}>
              <ActionView />
            </Box>
          </Col>
        </Then>
        <Else>
          <Col
            data-testid={"container-action-view"}
            xs={8}
            sm={8}
            md={12}
            lg={12}
          >
            <Box innerSpacing="spacing00">
              <ActionView />
            </Box>
          </Col>
        </Else>
      </If>
    </Row>
  );
}
