/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { EventAction, ExecutionStatus } from "~/generated/graphql";
import { Details, NoData, BorderWrapper } from "~/components";
import { ROUTES } from "~/routing/routes";
import { TooltipIconStatus } from "../../components";
import { useEvent } from "../EventProvider";
import { DetailsFirstPreset } from "./presets";
import { TimeDetails } from "./TimeDetails";

export function EventInfo() {
  const { event } = useEvent();
  if (event === null) {
    return <NoData message="no data" />;
  }

  const approveActions = event?.actions
    ?.map((action: EventAction) => action.globalState?.status)
    .filter(
      (state: ExecutionStatus) => state === ExecutionStatus.ApprovalRequired
    );
  return (
    <Box spacing="spacing02">
      <Row>
        <Col xs={8} sm={8} md={5} lg={8}>
          <BorderWrapper
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Wrap>
              <Box
                boxStyle="lightGrey"
                mb={theme.spacing.spacing04}
                data-testid={event?.status}
              >
                <TooltipIconStatus
                  status={event?.status}
                  actionLength={approveActions?.length}
                  type="Event"
                  size="lg"
                  actions={event?.actions ?? []}
                />
              </Box>
            </Wrap>
            <FlexContainer
              alignItems="flex-start"
              justifyContent="center"
              direction="column"
              css={{ height: "100%", marginBottom: "1rem" }}
            >
              <Text tag="div" size="sm" color={theme.color.text.text02}>
                Event name
              </Text>
              <If condition={event?.plan?.id}>
                <Then>
                  <ExtendedTooltip
                    caption="Go to event related plan"
                    position="start"
                  >
                    <Link to={`${ROUTES.plans.index}/${event?.plan?.id}`}>
                      {event?.name}
                    </Link>
                  </ExtendedTooltip>
                </Then>
                <Else>
                  <Text>{event?.name}</Text>
                </Else>
              </If>
            </FlexContainer>
          </BorderWrapper>
          <Row>
            <Col>
              <Details details={DetailsFirstPreset(event)} />
            </Col>
          </Row>
        </Col>
        <Col xs={8} sm={8} md={3} lg={4}>
          <TimeDetails
            timeDetails={{
              scheduleTimezone: event?.scheduleTimezone,
              startTime: event?.startTime,
              estimatedEndTime: event?.estimatedEndTime,
            }}
          />
        </Col>
      </Row>
    </Box>
  );
}

const Wrap = styled.div`
  margin-right: ${theme.spacing.spacing03};
`;
