/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useState, useEffect, useMemo } from "react";
import {
  GroupsSortFields,
  Maybe,
  SortOrder,
  useGetResourceGroupsLazyQuery,
} from "~/generated/graphql";

const REQUEST_LIMIT = 50;

type ResourceGroupItem = {
  id: string;
  name: string;
  resourceSelectors?: Maybe<
    {
      id: string;
      resource?: Maybe<{
        id: string;
        name: string;
      }>;
      order: number;
      tagsExpression?: Maybe<string>;
    }[]
  >;
};

export function useGetAllResourceGroups() {
  const [data, setData] = useState<ResourceGroupItem[]>([]);
  const [getData, { data: groupsData, loading, fetchMore, error }] =
    useGetResourceGroupsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-only",
    });

  const getGroups = useCallback(
    () =>
      getData({
        variables: {
          page: 1,
          limit: REQUEST_LIMIT,
          sort: {
            field: GroupsSortFields.CreatedAt,
            order: SortOrder.Asc,
          },
        },
      }),
    [getData]
  );

  const queryResults = useMemo(
    () => groupsData?.groups?.result ?? [],
    [groupsData?.groups?.result]
  );

  useEffect(() => {
    setData(queryResults);
  }, [queryResults]);

  const count = groupsData?.groups?.pageInfo?.count ?? 0;
  const pages = groupsData?.groups?.pageInfo?.pages ?? 0;

  useEffect(() => {
    if (!loading && count > queryResults.length) {
      [...Array(pages).keys()].slice(1).forEach((element) => {
        fetchMore?.({
          variables: {
            page: element + 1,
            limit: REQUEST_LIMIT,
            sort: {
              field: GroupsSortFields.Name,
              order: SortOrder.Asc,
            },
          },
        }).then(({ data: fetchedData }) => {
          const newAccessList: ResourceGroupItem[] =
            fetchedData.groups?.result ?? [];

          return setData((prev) => [...prev, ...newAccessList]);
        });
      });
    }
  }, [fetchMore, loading, count, pages, queryResults.length]);

  if (!loading && count > queryResults.length) {
    [...Array(pages).keys()].slice(1).forEach(async (element) => {
      await fetchMore?.({
        variables: {
          page: element + 1,
          limit: 50,
          sort: {
            field: GroupsSortFields.Name,
            order: SortOrder.Asc,
          },
        },
      });
    });
  }

  return { getGroups, data, loading, error, count } as const;
}
