/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, Text, theme, Switch } from "@nordcloud/gnui";
import { EventAction, EventActionAttempt } from "~/generated/graphql";
import { NoData } from "~/components";
import { useToggle } from "~/hooks";
import { isNonEmpty } from "~/tools";
import { EventToggleItemBox } from "~/views/events/components/EventToggleItemBox/EventToggleItemBox";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ReportView } from "~/views/events/EventsDetails/Tabs/Details/components/ReportView/ReportView";
import { getActionStatus } from "~/views/events/helpers";
import { EventActionBox } from "./components/ActionBox/EventActionBox";
import { EventDetailsTabCtxProvider } from "./EventDetailsTabProvider";

export function EventDetailsTab() {
  const { event } = useEvent();
  const actions = event.actions ?? [];

  const [eventAction, setEventAction] = useState<EventAction>(actions[0]);
  const [attempt, setAttempt] = useState<EventActionAttempt>();
  const [isReportView, toggleReportView] = useToggle(false);

  const handleSelectAttempt = (value: EventActionAttempt) => setAttempt(value);

  const resourceStates = attempt
    ? attempt?.resourceStates
    : eventAction.resourceStates;

  return (
    <Box innerSpacing="spacing00">
      <If condition={isNonEmpty(actions)}>
        <Then>
          <EventDetailsTabCtxProvider
            value={{ eventAction, attempt, setEventAction, setAttempt }}
          >
            <Row>
              <Col xs={8}>
                <FlexContainer
                  direction="row"
                  justifyContent="space-between"
                  padding={`${theme.spacing.spacing04} 0`}
                >
                  <div>
                    <Text width="100%" align="left" size="md" weight="bold">
                      Actions
                    </Text>
                  </div>
                  <div>
                    <Switch
                      name="label-left"
                      position="left"
                      labelText="Report View"
                      onChange={toggleReportView}
                    />
                  </div>
                </FlexContainer>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={8} md={3} lg={3}>
                <FlexContainer direction="column" justifyContent="flex-start">
                  {actions?.map((item: EventAction) => {
                    const attempts =
                      item.attempts?.length > 1 ? item.attempts : [];

                    return (
                      <EventToggleItemBox
                        key={item.id}
                        item={{
                          id: item.id,
                          color: item.action?.color ?? theme.color.support.grey,
                          name: item.name,
                          icon: getActionStatus(item.globalState?.status).icon,
                          iconColor: getActionStatus(
                            item.globalState?.status
                          ).color(),
                          tooltip: getActionStatus(item.globalState?.status)
                            .description,
                          tooltipColor: getActionStatus(
                            item.globalState?.status
                          ).tooltipColor,
                          onMouseDown: () => {
                            setEventAction(item);
                            setAttempt(undefined);
                          },
                          onSelect: handleSelectAttempt,
                        }}
                        attempts={attempts}
                        selectedAttempt={attempt}
                        active={item.id === eventAction.id}
                      />
                    );
                  })}
                </FlexContainer>
              </Col>
              <Col xs={4} sm={8} md={5} lg={9}>
                <Box boxStyle="grey" innerSpacing="spacing04">
                  <FlexContainer alignItems="flex-start">
                    <If condition={isReportView}>
                      <Then>
                        <ReportView
                          eventId={event.id}
                          eventActionId={eventAction.id}
                          attempt={attempt}
                        />
                      </Then>
                      <Else>
                        <EventActionBox
                          eventAction={eventAction}
                          resourceStates={resourceStates}
                          planId={event?.plan?.id}
                          attempt={attempt}
                        />
                      </Else>
                    </If>
                  </FlexContainer>
                </Box>
              </Col>
            </Row>
          </EventDetailsTabCtxProvider>
        </Then>
        <Else>
          <NoData hasIcon message="There are no Actions" />
        </Else>
      </If>
    </Box>
  );
}
