/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExtendedTooltip, SVGIcon } from "@nordcloud/gnui";
import { SvgName } from "~/tools/types";

type Props = {
  icon: SvgName;
  caption: string;
};

export function TabIcon({ icon, caption }: Props) {
  return (
    <span>
      <ExtendedTooltip caption={caption}>
        <SVGIcon name={icon} />
      </ExtendedTooltip>
    </span>
  );
}
