/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useState } from "react";
import { RowSelectionState } from "@tanstack/react-table";
import { ResourcesSortFields, SortOrder } from "~/generated/graphql";
import { Pagination } from "~/components/Pagination";
import { TableWrapper } from "~/components/Tables";
import { useQueryState } from "~/hooks";
import { isNotNil, isNotEmpty } from "~/tools";
import { useGetResources } from "~/views/resources/hooks";
import { Resource, QuerySort } from "~/views/resources/types";
import { ResourceCtxProvider } from "../ResourceProvider";
import { ResourceListHeader } from "./ResourceListHeader";
import { columns } from "./ResourcesColumns";

type Props = {
  providerId?: string;
};

export function ResourcesTable({ providerId }: Props) {
  const [resourcesIds, setResourcesIds] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const {
    queryState: { page, limit, field, order },
  } = useQueryState<QuerySort>();

  const { data, loading, count } = useGetResources({
    page: page,
    limit: limit,
    sort: {
      field: field ?? ResourcesSortFields.Name,
      order: order ?? SortOrder.Asc,
    },
    filter: {
      providerId: providerId,
    },
  });
  const cleanRowSelection = () => setRowSelection({});
  if (isNotEmpty(data) || loading) {
    return (
      <ResourceCtxProvider value={{ cleanRowSelection }}>
        <ResourceListHeader resourcesIds={resourcesIds} />
        <TableWrapper<Partial<Resource>>
          loading={loading}
          data={data.filter(isNotNil)}
          columns={columns()}
          selectedRowsHandler={setResourcesIds}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          stripped
        />
        <Pagination count={count} />
      </ResourceCtxProvider>
    );
  }

  return <>No Resources available</>;
}
