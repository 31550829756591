/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { string, z } from "zod";
import {
  Button,
  Datepicker,
  DayPicker,
  Modal,
  Text,
  Input,
  Label,
  Sidebar,
  Spacer,
  theme,
  FlexContainer,
} from "@nordcloud/gnui";
import {
  CreateApiKeyInput,
  CreateApiKeyMutation,
  RbacRoleBindingType,
} from "~/generated/graphql";
import { CodeHighlighter } from "~/components/CodeHighlighter";
import { FormGroup, stringRequired } from "~/components/Forms";
import { dateFormat } from "~/constants";
import { useDisclosure } from "~/hooks";
import { useAuth0 } from "~/services";
import { useCreateApiKey } from "~/views/permissions/APIKeys/hooks/useCreateApiKey/useCreateApiKey";
import { UserRolesSidebar } from "~/views/permissions/components/UserRolesSidebar";
import { useCreateRoleBinding } from "~/views/permissions/hooks/useCreateRoleBinding/useCreateRoleBinding";

export const schema = z.object({
  name: stringRequired("name"),
  owner: stringRequired("owner"),
  expiresAt: string(),
});

type State = {
  roleIds: string[];
  expires: string;
  secretKey: string;
};

export function ApiKeysCreateSidebar() {
  const { user } = useAuth0();
  const { isOpen, open, close } = useDisclosure();
  const {
    isOpen: isDateModalOpen,
    open: opeDateModal,
    close: closeDateModal,
  } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    open: openConfirmation,
    close: closeConfirmation,
  } = useDisclosure();

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    { roleIds: [], expires: "", secretKey: "" }
  );

  const { handleSubmit, register, formState, reset, clearErrors, setValue } =
    useForm({
      resolver: zodResolver(schema),
      defaultValues: {
        name: "",
        expiresAt: "",
        owner: user?.email ?? "",
      },
    });

  const [createRoleBinding] = useCreateRoleBinding({ onSuccess: () => null });

  const [createApiKey, loading] = useCreateApiKey({
    onSuccess: (response: CreateApiKeyMutation) => {
      if (response.createApiKey.apiKeySecret) {
        close();
        openConfirmation();
        updateState({
          secretKey: response.createApiKey.apiKeySecret,
          expires: "",
          roleIds: [],
        });
      }

      if (response.createApiKey.apiKey.id && state.roleIds.length !== 0) {
        Promise.all(
          state.roleIds.map((id) =>
            createRoleBinding({
              roleId: id,
              subject: response.createApiKey.apiKey.id,
              type: RbacRoleBindingType.ApiKey,
            })
          )
        );
      }
      reset();
    },
  });

  const onSubmit = (data: CreateApiKeyInput) => {
    createApiKey(data);
  };

  const clean = () => {
    reset();
    clearErrors();
    setValue("expiresAt", "");
    updateState({ expires: "", roleIds: [] });
    close();
  };

  return (
    <>
      <Button mr={theme.spacing.spacing04} icon="plus" onClick={open}>
        Create API Key
      </Button>
      <Sidebar title="Create API Key" isOpen={isOpen} onClick={clean}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={formState.errors["name"]}>
            <Label name="API Key Name" htmlFor="name" required />
            <Input
              id="name"
              placeholder="API Key Name"
              {...register("name")}
              disabled={loading}
            />
          </FormGroup>
          <FormGroup error={formState.errors["expiresAt"]}>
            <Label name="Expires" htmlFor="expiresAt" />
            <Input
              placeholder="dd.mm.yyyy"
              value={state.expires}
              readOnly
              onClick={opeDateModal}
              icon="calendar"
              disabled={loading}
            />
            <Sidebar
              isOpen={isDateModalOpen}
              onClick={() => {
                closeDateModal();
                updateState({ expires: "" });
              }}
              footer={
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                  p={3}
                  css={{ width: "100%" }}
                >
                  <Button onClick={closeDateModal}>Apply</Button>
                </FlexContainer>
              }
            >
              <FlexContainer alignItems="center" justifyContent="center">
                <Datepicker>
                  <DayPicker
                    id="expiresAt"
                    mode="single"
                    disabled={{ before: new Date() }}
                    selected={dayjs(state.expires).toDate()}
                    onSelect={(data: Date | undefined) => {
                      const expiresAt = dayjs(data)
                        .endOf("day")
                        .format(dateFormat.fullDate);
                      setValue("expiresAt", expiresAt);
                      updateState({
                        expires: dayjs(data).format(dateFormat.dayMonthYear),
                      });
                    }}
                  />
                </Datepicker>
              </FlexContainer>
            </Sidebar>
          </FormGroup>
          <FormGroup>
            <Label name="User Role" htmlFor="userRole" />
            <UserRolesSidebar
              ids={state.roleIds}
              onSelect={(roleIds: string[]) =>
                updateState({ roleIds: roleIds })
              }
              isLoading={loading}
            />
          </FormGroup>
          <Spacer height={theme.spacing.spacing02} />
          <Button
            disabled={loading}
            initialState={loading ? "loading" : "success"}
          >
            Apply
          </Button>
        </form>
      </Sidebar>
      <Modal
        isOpen={isConfirmationOpen}
        onClose={() => {
          closeConfirmation();
          updateState({ secretKey: "" });
        }}
        data-testid="modal-api-key-created"
        contentLabel={"API Key Created"}
        actions={[
          {
            order: 0,
            onAction: closeConfirmation,
            label: "Close",
            severity: "high",
          },
        ]}
      >
        Make sure to copy the secret key.
        <br />
        <Text color="danger" weight="bold">
          You won’t see it again!
        </Text>
        Secret Key:
        <br />
        <CodeHighlighter language="text" code={state.secretKey} />
      </Modal>
    </>
  );
}
