/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormProvider,
  useFieldArray,
  useForm,
  SubmitHandler,
} from "react-hook-form";
import { z } from "zod";
import { Button } from "@nordcloud/gnui";
import { stringRequired } from "~/components/Forms";
import { isNotNil } from "~/tools";
import { NotificationItem } from "~/views/plans/PlanCreate/components/NotificationItem";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import {
  FormField,
  NotificationGroupItem,
  SelectedAction,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { PlanErrors, PlanField } from "../../../constants";
import { usePlanWizard } from "../../../PlanProvider";
import { useStepState } from "../../StepProvider";
import { isAdvanceTrigger } from "../utils";

export const NotificationGroupBaseSchema = {
  [FormField.SCOPE]: stringRequired("Subject"),
  [FormField.TRIGGER_EVENT]: z.string().optional(),
  [FormField.NOTIFICATION_GROUPS_IDS]: stringRequired("Notification Recipent")
    .array()
    .nonempty(),
  [FormField.IN_ADVANCE]: z.number().optional(),
  [FormField.IN_ADVANCE_UNIT]: z.string().optional(),
};

export const NotificationGroupSchema = z
  .object(NotificationGroupBaseSchema)
  .refine(
    (data) => {
      if (isAdvanceTrigger(data.triggerEvent)) {
        return isNotNil(data.inAdvance) && data.inAdvance > 0;
      }
      return true;
    },
    {
      message: "In Advance has to been greater than 0",
      path: [FormField.IN_ADVANCE],
    }
  );

const NotificationGroupsSchema = z.object({
  [FormField.NOTIFICATION_GROUPS]: z.array(NotificationGroupSchema),
});

type Props = {
  nextStep?: () => void;
};

export function NotificationForm({ nextStep }: Props) {
  const { stepState, updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  const defaultValues = useMemo(() => {
    return (
      stepState.selectedAction?.notificationGroups?.map((i) => {
        const ids = i.notificationGroup?.id
          ? [i?.notificationGroup?.id]
          : i.notificationGroupIds;
        return {
          [FormField.ID]: i.id,
          [FormField.NOTIFICATION_GROUPS_IDS]: ids,
          [FormField.SCOPE]: i.scope,
          [FormField.TRIGGER_EVENT]: i.triggerEvent,
          [FormField.IN_ADVANCE]: i.inAdvance ?? 0,
          [FormField.IN_ADVANCE_UNIT]:
            i[FormField.IN_ADVANCE_UNIT] ?? TimeUnits.minutes,
        };
      }) ?? []
    );
  }, [stepState.selectedAction?.notificationGroups]);

  const formMethods = useForm<FormData>({
    resolver: zodResolver(NotificationGroupsSchema),
    defaultValues: {
      [FormField.NOTIFICATION_GROUPS]: defaultValues,
    },
  });

  const { control, handleSubmit } = formMethods;

  const submit: SubmitHandler<FormData> = () => {
    nextStep?.();
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: FormField.NOTIFICATION_GROUPS,
  });

  const setPlanError = useCallback(
    (planError: PlanErrors) => {
      setPlanData((prevPlanData) => {
        if ((prevPlanData[PlanField.ERRORS] ?? []).includes(planError)) {
          return prevPlanData;
        }
        return {
          ...prevPlanData,
          [PlanField.ERRORS]: [
            ...(prevPlanData[PlanField.ERRORS] ?? []),
            planError,
          ],
        };
      });
    },
    [setPlanData]
  );

  const clearPlanError = useCallback(
    (planError: PlanErrors) => {
      setPlanData((prevPlanData) => ({
        ...prevPlanData,
        [PlanField.ERRORS]: prevPlanData[PlanField.ERRORS]?.filter(
          (errors) => errors !== planError
        ),
      }));
    },
    [setPlanData]
  );

  const handlePlanErrors = useCallback(
    (toValidate: NotificationGroupItem[]) => {
      const isValid = toValidate
        ?.map((value) => NotificationGroupSchema.safeParse(value).success)
        .every((value) => value);
      if (isValid) {
        clearPlanError(PlanErrors.PLAN_NOTIFICATION_GROUPS);
      } else {
        setPlanError(PlanErrors.PLAN_NOTIFICATION_GROUPS);
      }
    },
    [clearPlanError, setPlanError]
  );

  const updateSelectedAction = (updateObject: Partial<SelectedAction>) => {
    const actionIndex = (stepState.actions ?? []).findIndex(
      (action) => action.listId === stepState.selectedAction?.listId
    );
    const newActions = [
      ...(stepState.actions ?? []).slice(0, actionIndex),
      { ...stepState.selectedAction, ...updateObject },
      ...(stepState.actions ?? []).slice(actionIndex + 1),
    ];
    updateStepState({
      actions: newActions,
      selectedAction: { ...stepState.selectedAction, ...updateObject },
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(newActions ?? [])],
      },
    }));
  };
  const onAddNewNotification = () => {
    const template = {
      [FormField.SCOPE]: undefined,
      [FormField.TRIGGER_EVENT]: undefined,
      [FormField.NOTIFICATION_GROUPS_IDS]: undefined,
    };

    append(template);
    const stepActions = stepState?.actions ?? [];
    const actionIndex =
      stepActions?.findIndex(
        (action) => action.listId === stepState.selectedAction?.listId
      ) ?? -1;
    const notificationGroups = [...defaultValues, template];
    const updatedAction = {
      ...stepActions?.[actionIndex],
      [FormField.NOTIFICATION_GROUPS]: notificationGroups,
    };
    updateSelectedAction(updatedAction);

    handlePlanErrors(notificationGroups);
  };

  useEffect(() => {
    replace(defaultValues);
  }, [defaultValues, replace]);

  return (
    <FormProvider {...formMethods}>
      <form id="planSettingsForm" onSubmit={handleSubmit(submit)}>
        {fields.map((field, index) => {
          return (
            <NotificationItem
              key={field.id}
              index={index}
              remove={() => remove(index)}
              handlePlanErrors={handlePlanErrors}
            />
          );
        })}
        <Button
          severity="low"
          icon="plus"
          type="button"
          onClick={onAddNewNotification}
        >
          Add Notification
        </Button>
      </form>
    </FormProvider>
  );
}
