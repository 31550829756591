/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { Else, If, Then } from "react-if";
import { v4 } from "uuid";
import {
  Button,
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Sidebar,
  theme,
} from "@nordcloud/gnui";
import {
  Action,
  SystemAction,
  ActionSortFields,
  PlanActionType,
  SystemActionsSortFields,
  ActionParameterDefinition,
  KeyValue,
} from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { sortItem } from "~/tools";
import { useGetCustomActions } from "~/views/customActions/hooks/useGetCustomActions/useGetCustomActions";
import { useGetCustomSystemActions } from "~/views/plans/hooks/useGetSystemActions/useGetSystemActions";
import { defaultActionSettingsValues, PlanField } from "../../constants";
import { usePlanWizard } from "../../PlanProvider";
import { FormField, SelectedAction } from "../../types";
import { useStepState } from "../StepProvider";
import { ActionItem } from "./ActionItem";

const ACTIONS_LIMIT = "100";

type State = {
  isSideBarOpen: boolean;
  actionType: PlanActionType;
  action?: Partial<Action> | Partial<SystemAction>;
};

const defaultState = {
  isSideBarOpen: false,
  actionType: PlanActionType.System,
  action: undefined,
};

type Props = {
  buttonSeverity?: "low";
  buttonName?: string;
};

export function AddAction({ buttonSeverity, buttonName }: Props) {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({
      ...data,
      ...partialData,
    }),
    defaultState
  );

  const { stepState, updateStepState } = useStepState();

  const actionsType = [
    {
      label: "System Actions",
      value: PlanActionType.System,
    },
    {
      label: "Custom Actions",
      value: PlanActionType.Custom,
    },
  ];

  const { data: customActions, loading: loadingCustomActions } =
    useGetCustomActions({
      page: "1",
      limit: ACTIONS_LIMIT,
      sort: {
        field: ActionSortFields.Name,
      },
    });

  const { data: systemActions, loading: loadingSystemActions } =
    useGetCustomSystemActions({
      page: "1",
      limit: ACTIONS_LIMIT,
      sort: {
        field: SystemActionsSortFields.Name,
      },
    });

  const sortActionBasedOnVersion = sortItem(
    systemActions,
    (a, b) => parseFloat(a.version) - parseFloat(b.version)
  );
  const removeDuplicatedActionWithVersions = [
    ...sortActionBasedOnVersion
      .reduce((a, c) => a.set(c.name, c), new Map())
      .values(),
  ];

  const closeSidebar = () => updateState({ isSideBarOpen: false });

  const { setPlanData } = usePlanWizard();

  const selectAction = (item: SelectedAction) => {
    const inputParameters = extractInputParameters(item?.parameterDefinitions);

    const actionSettingsValues = {
      ...defaultActionSettingsValues,
      [FormField.INPUT_PARAMETERS]: inputParameters,
    };

    const action = {
      ...actionSettingsValues,
      ...item,
      listId: v4(),
      actionType: state.actionType,
      actionName: item.name,
    };
    updateStepState({
      selectedAction: action,
      actions: [...(stepState.actions ?? []), action],
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(stepState.actions ?? []), action],
      },
    }));
    closeSidebar();
  };

  const actionsList = (actions: SelectedAction[]) =>
    actions?.map((a) => {
      const actionItem = {
        id: a.id ?? "",
        name: a.name,
        description: a.description ?? "",
        color: a.color,
        actionsType: a.actionType,
      };

      return (
        <ActionItem
          key={a.id}
          item={actionItem}
          onMouseDown={() => selectAction(a)}
        />
      );
    });

  return (
    <>
      <Button
        data-testid="button-add-action"
        onClick={() => updateState({ isSideBarOpen: true })}
        mt={theme.spacing.spacing01}
        severity={buttonSeverity}
      >
        {buttonName}
      </Button>
      <Sidebar
        title="Select Action"
        isOpen={state.isSideBarOpen}
        onClick={() => updateState({ isSideBarOpen: false })}
      >
        <LoaderWrap
          inContent
          loading={loadingCustomActions || loadingSystemActions}
        >
          <FlexContainer mb={theme.spacing.spacing05}>
            <MultipleSelect size="small">
              {actionsType.map((action) => (
                <SelectButton
                  key={action.value}
                  name={action.value}
                  value={action.value}
                  labelText={action.label}
                  onClick={() => updateState({ actionType: action.value })}
                  isActive={state.actionType === action.value}
                />
              ))}
            </MultipleSelect>
          </FlexContainer>
          <If condition={state.actionType === PlanActionType.Custom}>
            <Then>{actionsList(customActions)}</Then>
            <Else>{actionsList(removeDuplicatedActionWithVersions)}</Else>
          </If>
        </LoaderWrap>
      </Sidebar>
    </>
  );
}

function extractInputParameters(
  parameterDefinitions?: ActionParameterDefinition[]
): KeyValue[] {
  return (
    parameterDefinitions
      ?.filter((param) => param.defaultValue)
      .map((param) => ({
        key: param.name,
        value: param.defaultValue ?? "",
      }))
      .filter((param) => param.value.length !== 0) ?? []
  );
}
