/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import { Spacer, Text, theme } from "@nordcloud/gnui";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { convertMinutesToTime } from "~/views/events/helpers";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";

export function GeneralSettings() {
  const { planAction } = usePlan();

  const isDelay = planAction?.action?.name === SystemActionNames.DELAY;

  return (
    <>
      <Text weight="bold">General Action Settings</Text>
      <DisplayExecutionPolicy policy={planAction?.executionPolicy} />
      <If condition={isDelay}>
        <Then>
          <Text size="sm" tag="div">
            Action Delay
          </Text>
          <Text data-testid="text-action-delay" mb={theme.spacing.spacing01}>
            {convertMinutesToTime(Number(planAction?.windowDuration))}
          </Text>
          <Spacer height={theme.spacing.spacing03} />
        </Then>
        <Else>
          <DisplayWindowDuration windowDuration={planAction?.windowDuration} />
        </Else>
      </If>
      <DisplayBooleanProperties
        title="Resource Operation"
        condition={planAction?.runInSequence}
        options={["Sequenced", "Simultaneous"]}
      />
      <When condition={planAction?.skipWindow}>
        <Text size="sm" tag="div">
          Ignore Window
        </Text>
        <Text data-testid="text-ignore-window" mb={theme.spacing.spacing01}>
          {convertSkipWindow(planAction?.skipWindow)}
        </Text>
        <Spacer height={theme.spacing.spacing04} />
      </When>
    </>
  );
}
