/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { getDroppableCss } from "~/components/Styled";
import { reorder, useComponentsSelector } from "~/hooks";
import { isEmpty } from "~/tools";
import { Item } from "~/types";
import { PlanField } from "../../constants";
import { usePlanWizard } from "../../PlanProvider";
import { useStepState } from "../StepProvider";
import { DraggableActionList } from "./DraggableActionsList";

type Props = { actions?: Item[]; nextStep?: () => void };

export function DraggableActions({ actions, nextStep }: Props) {
  const { items, handleDragEnd, handleDelete, handleShowSearch } =
    useComponentsSelector([]);

  const { updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  useEffect(() => {
    if (actions) {
      handleShowSearch(actions);
    }
  }, [actions, handleShowSearch]);

  const handleDrag = (result: DropResult) => {
    handleDragEnd(result);

    const newActions = reorder(
      actions ?? [],
      result.source.index,
      result.destination?.index ?? -1
    );
    updateStepState({
      actions: newActions,
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(newActions ?? [])],
      },
    }));
  };

  if (isEmpty(items.SELECTED_ITEMS)) {
    return null;
  }
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={"SELECTED_ITEMS"}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            css={getDroppableCss(true, snapshot.isDraggingOver)}
          >
            <DraggableActionList
              items={items["SELECTED_ITEMS"]}
              onDelete={handleDelete}
              nextStep={nextStep}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
